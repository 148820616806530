import _ from 'lodash'
import { retry } from '@42technologies/retry'
import { ConfigAPI } from './config-api'
import { QueryServiceAPI } from './api/api-query-service'
import { CurrenciesService, CurrencyModelService } from '../modules/currency/currency.service'
import { isObject, seconds, CustomError } from './utils'


###*
@argument {import('./types').IMetricDefinition[]} metrics
###
normalizeMetrics = (metrics) ->
    return normalizeMetricCurrencyTemplate(metrics)

###*
@argument {string} label
@returns {string}
###
normalizeLabel = (label) ->
    return label.replace(/\[\s*currency\s*\]/ig, '{{ currency }}')

###*
@argument {import('./types').IMetricDefinition[]} metrics
###
normalizeMetricCurrencyTemplate = (metrics) ->
    metrics = _.cloneDeep(metrics)
    metrics.forEach (metric) ->
        metric.headerGroup = normalizeLabel(metric.headerGroup) if typeof metric.headerGroup is 'string'
        metric.headerName  = normalizeLabel(metric.headerName) if typeof metric.headerName is 'string'

    return metrics

###*
@argument {import('./types').IMetricDefinition[]} metrics
@argument {string | {symbol?: string}} [currency]
###
applyCurrencyToMetrics = (metrics, currency) ->
    currency = if typeof currency is 'string' then {symbol:currency} else currency
    currency = currency?.symbol ? '$'
    return metrics.map (x) -> {
        ...x,
        headerGroup: template(normalizeLabel(x.headerGroup), {currency}) if typeof x.headerGroup is 'string',
        headerName:  template(normalizeLabel(x.headerName), {currency}) if typeof x.headerName is 'string',
    }

###*
@argument {import('./types').IMetricDefinition[]} metrics
@argument {import('./types').IKpis['categoryOverrides']} [categoryOverrides]
###
# applyCategoryOverridesToMetrics = (metrics, categoryOverrides) ->
#     return metrics if not _.isObject(categoryOverrides)
#     metrics = _.cloneDeep(metrics)
#     metricsWithCategory = metrics.filter((x) -> typeof x.category is 'string')
#     metricsByCategory = _.groupBy(metricsWithCategory, 'category')
#     categoryOverrides = _.cloneDeep(categoryOverrides)
#     for [category, categoryOverride] from Object.entries(categoryOverrides)
#         console.log("category:", category, "categoryOverride:", categoryOverride)
#         categoryMetrics = metricsByCategory[category] or []
#         for metric from categoryMetrics
#             console.log(metric)
#             for [key, value] from Object.entries(categoryOverride)
#                 metric[key] = template(value, metric)
#     return metrics


###*
@argument {import('./types').IMetricDefinition[]} metrics
@argument {import('./types').IKpis['categoryOverrides']} [categoryOverrides]
###
applyCategoryOverridesToMetrics = (metrics, categoryOverrides) ->
    metrics = _.cloneDeep(metrics)
    metricsByCategory = _.groupBy metrics, (x) -> x.category

    overrides = do ->
        return {} if not _.isObject(categoryOverrides)
        categoryOverrides = _.cloneDeep(categoryOverrides)

        overrides = Object.entries(categoryOverrides).flatMap ([category, categoryOverride]) ->
            categoryMetrics = metricsByCategory[category] or []
            if categoryMetrics.length is 0
                console.warn("Can't override metric category, the category does not exist:", category)
            return categoryMetrics.map (x) ->
                override = _.cloneDeep(categoryOverride)
                override.field = x.field
                return override

        result = _.keyBy(overrides, (x) -> x.field)
        return Object.keys(result).reduce((obj, x) ->
            delete result[x].field
            obj[x] = result[x]
            return obj
        , {})

    return applyOverridesToMetrics(metrics, overrides)


###*
@argument {import('./types').IMetricDefinition[]} metrics
@argument {import('./types').IKpis['overrides']} [overrides]
@returns {import('./types').IMetricDefinition[]} metrics
###
applyOverridesToMetrics = (metrics, overrides) ->
    metrics = _.cloneDeep(metrics)
    metricsByField = _.keyBy(metrics, (x) -> x.field)

    overrides = do ->
        result = _.cloneDeep(overrides or {})
        return {} if not isObject(result)
        return result

    for field from Object.keys(overrides)
        override = overrides[field]
        if not isObject(override)
            console.warn("Metric override `#{field}` is not an object:", override)
            continue
        metric = metricsByField[field]
        if not metric
            # console.warn "Metric override `#{field}` has no matching metric."
            # console.warn override
            continue
        for key in Object.keys(_.omit(override, 'field'))
            override[key] = template(override[key], metric)
        _.extend(metric, override)

    return metrics


# template = (str, locals) ->
#     str = str.replaceAll(/[\n|\t|\r]/g, '')
#     return str.replaceAll /{{[{]?(.*?)[}]?}}/g, (match, p1) ->
#         return match if (typeof p1 isnt 'string')
#         p1 = p1.trim()
#         return match if not _.has(locals, p1)
#         return _.get(locals, p1)

template = (str, locals) ->
    templateOptions = {interpolate:/{{([\s\S]+?)}}/g}
    return _.template(str, templateOptions)(locals)


###* @argument {import('./types').IConfigObj} [orgConfig] ###
fetchCustomMetricFilters = (orgConfig) ->
    orgConfig = orgConfig ? await do ->
        api = await ConfigAPI.get()
        return await api.organization.get()
    return orgConfig.kpis?.filters ? {}


###*
@argument {import('./types').IConfigObj} [orgConfig]
###
fetchCustomMetricDefinitions = (orgConfig) ->
    orgConfig = orgConfig ? await do ->
        api = await ConfigAPI.get()
        return await api.organization.get()
    definitions = orgConfig.kpis?.definitions ? {}
    return Object.keys(definitions).map (id) ->
        return {...definitions[id], field: id}


###*
@argument {import('./types').IConfigObj} [orgConfig]
###
fetchCustomFoundationMetricDefinitions = (orgConfig) ->
    orgConfig = orgConfig ? await do ->
        api = await ConfigAPI.get()
        return await api.organization.get()
    foundations = orgConfig.kpis?.foundations ? {}
    return Object.values(foundations).reduce ((result, foundation) ->
        Object.keys(foundation).forEach (id) -> result[id] = {...foundation[id], field: id}
        return result
    ), {}



class ConfigMetricsError extends CustomError
class ConfigMetricsFetchDefaultMetricsError extends ConfigMetricsError

###* @returns {Promise<import('./api/api-query-service').QueryServiceMetric[]>} ###
fetchDefaultMetrics = ->
    return await retry((() ->
        api = await QueryServiceAPI.get()
        metrics = await api.organizations.getMetrics({})
        throw new ConfigMetricsFetchDefaultMetricsError("invalid query service metrics response; not an array (#{typeof metrics})") if not Array.isArray(metrics)
        throw new ConfigMetricsFetchDefaultMetricsError("invalid query service metrics response; array is empty") if metrics.length is 0
        return metrics
    ), {logger: console, delay: { max: seconds(3) }})


fetchStandardMetrics = ->
    [standardMetrics, customFoundationMetrics] = await Promise.all([
        fetchDefaultMetrics(),
        fetchCustomFoundationMetricDefinitions()
    ])
    return Object.values({
        ..._.keyBy(standardMetrics, 'field'),
        ..._.keyBy(customFoundationMetrics, 'field')
    })


###*
@argument {(import('./types').IMetricDefinition | import('./api/api-query-service').QueryServiceMetric)[]} metrics
@argument {import('./types').IKpiItem[]} definitions
@argument {Required<import('./types').IKpis>['filters']} metricFilters
@returns {import('./types').IMetricDefinition[]} definitions
###
convertMetricFiltersToDefinitions = (metrics, definitions, metricFilters) ->
    metricsByField = _.keyBy(metrics, 'field')
    definitionsByField = _.keyBy(definitions, 'field')
    errors = []
    result = Object.entries(metricFilters).flatMap ([prefix, metricFilter]) -> metricFilter.metrics.flatMap (metricId) ->
        field = "#{prefix}_#{metricId}"
        if definitionsByField[field]
            errors.push(['Filtered metric', field, 'already defined.'])
            return []
        metric = metricsByField[metricId]
        if not metric
            errors.push(['Filtered metric', field, 'is missing definition for', metricId])
            return []
        if not metricFilter.label
            errors.push(['Filtered metric', field, 'is missing label.'])
            return []
        return [{
            ..._.cloneDeep(metric),
            headerGroup: "#{metricFilter.label} #{metric.headerGroup}",
            fields: [field],
            field: field,
            query: field,
        }]
    if errors.length isnt 0
        console.group("[config-metrics][convertMetricFiltersToDefinitions] Errors:")
        errors.forEach (error) -> console.warn(...error)
        console.groupEnd()
    return result


fetchAvailableMetrics = ->
    [metrics, definitions, metricFilters] = await Promise.all([
        fetchStandardMetrics(),
        fetchCustomMetricDefinitions(),
        fetchCustomMetricFilters()
    ])
    definitionFilters = convertMetricFiltersToDefinitions(metrics, definitions, metricFilters)
    return [...metrics, ...definitions, ...definitionFilters]


###*
@returns {Promise<null | string[]>} selectedMetrics
###
fetchSelectedMetrics = ->

    ###*
    @argument {unknown} x
    @returns {null | string[]}
    ###
    normalizeArray = (x) ->
        return null if not Array.isArray(x)
        return _.uniq x.flatMap((id) -> if typeof id is 'string' then [id] else [])

    ###*
    @argument {unknown} selected
    @argument {null | string[]} available
    ###
    normalizeArrayOrObject = (selected, available) ->
        return normalizeArray(selected) if Array.isArray(selected)
        return null if not isObject(selected)
        throw new Error("No org metrics configured, can't patch!") if not available
        additions = normalizeArray(selected.add) ? []
        deletions = normalizeArray(selected.del) ? []
        return _.uniq([...available, ...additions].filter((id) -> not deletions.includes(id)))

    api = await ConfigAPI.get()
    userConfig = await api.user.getInternal()
    orgConfig = await api.organization.get()
    org  = normalizeArray(orgConfig.views?.metrics?.kpis)
    user = normalizeArrayOrObject(userConfig.accessControl?.kpis, org)

    return user ? org ? null


###*
@returns {Promise<import('./types').IKpis['categoryOverrides']>} categoryOverrides
###
fetchKpisCategoryOverrides = ->
    api = await ConfigAPI.get()
    userConfig = await api.user.getInternal()
    orgConfig = await api.organization.get()
    return userConfig.kpis?.categoryOverrides ? orgConfig.kpis?.categoryOverrides ? {}


###*
@returns {Promise<import('./types').IKpis['overrides']>} overrides
###
fetchKpisOverrides = ->
    api = await ConfigAPI.get()
    userConfig = await api.user.getInternal()
    orgConfig = await api.organization.get()
    return userConfig.kpis?.overrides ? orgConfig.kpis?.overrides ? {}


export QueryMetrics = ->
    metricsCache = null
    currenciesCache = null

    resolveSelectedMetrics = (available, selected) ->
        available = available.filter (x) -> typeof x.field is 'string'
        metricsByField = _.keyBy(available, (x) -> x.field)
        return available if not selected
        return selected.reduce(((acc, x) ->
            acc.push(metricsByField[x]) if metricsByField[x]
            return acc
        ), [])

    # Temporary fix until the customer page is fully connected to the config of an org.
    # The added metrics do not show up in the regular reports. They are only used for the query service.
    addMissingCustomerPageMetrics = (selected) ->
        return _.uniq([
            ...selected,
            "demand_net_sales",
            "demand_transaction_count",
            "demand_dollar_per_transaction",
            "demand_latest_order_timestamp"
        ])

    fetchMetrics = ->
        [available, selected, categoryOverrides, overrides] = await Promise.all([
            fetchAvailableMetrics()
            fetchSelectedMetrics()
            fetchKpisCategoryOverrides()
            fetchKpisOverrides()
        ])
        selected = addMissingCustomerPageMetrics(selected)
        metrics = resolveSelectedMetrics(available, selected)
        console.groupCollapsed("[config-metrics] Resolved Metrics")
        metrics.forEach (x) -> console.log(x)
        console.groupEnd()
        metrics = applyCategoryOverridesToMetrics(metrics, categoryOverrides)
        metrics = applyOverridesToMetrics(metrics, overrides)
        metrics = normalizeMetrics(metrics)
        return metrics

    getMetrics = ->
        metricsCache ?= fetchMetrics()
        return metricsCache.then((x) -> _.cloneDeep(x))

    getCurrencies = ->
        currenciesCache ?= CurrenciesService.fetch().then (currencies) ->
            return
                byId: _.keyBy(currencies, 'id')
                bySymbol:     _.keyBy(currencies, 'symbol')
        return currenciesCache.then((x) -> _.cloneDeep(x))

    return
        applyCurrencyToMetrics: applyCurrencyToMetrics

        ###* @argument {undefined | string | {symbol: string}} [currency] ###
        fetch: (currency) ->
            getCurrency = ->
                currency = currency?.symbol if isObject(currency)
                if currency and typeof currency is 'string'
                    currencies = await getCurrencies()
                    currency = currencies.byId[currency] or currencies.bySymbol[currency]
                    return currency.symbol if currency
                return CurrencyModelService.fetch().then((x) -> x.selected?.symbol)

            [metrics, currency] = await Promise.all([
                getMetrics()
                getCurrency()
            ])
            return applyCurrencyToMetrics(metrics, currency)

import _ from 'lodash';
import {
    ICreateMetricSelectorTreeParamsOptions,
    IMetricSelectorTreeInstance,
    createMetricSelect,
} from './metrics-selector-tree/metrics-selector-tree.builder';
import { IPropertyDefinition } from '../lib/config-hierarchy';
import { ToggleModel } from '../lib/model/model-toggle';
import { IMetricDefinition } from '../lib/types';
import { KeyboardObserverAngular } from '../lib/dom/keyboard-observer';
import { MouseObserverAngular } from '../lib/dom/mouse-observer';
import { IncludesFilter } from '../filters/includes.filter';
import { IListCustomHeaderOptions, IListCustomHeaderParamsOptions } from './list-custom-header';

export class SidebarToggleModel extends ToggleModel {
    tab: string;
    constructor(options?: { tab?: string; isOpen?: boolean }) {
        super(options?.isOpen);
        this.tab = options?.tab ?? '';
    }

    override toggle(event?: Event, tab?: string) {
        if (!tab || (tab === this.tab && this.isOpen)) {
            this.tab = '';
            return super.close(event);
        }

        if (this.tab !== tab && this.isOpen) {
            this.tab = tab ?? this.tab;
            return super.open(event);
        }

        this.tab = tab ?? this.tab;
        return super.toggle(event);
    }

    override open(event?: Event, tab?: string) {
        this.tab = tab ?? this.tab;
        return super.open(event);
    }
}

export interface ISidebarPropertiesMenuModel {
    selected: IPropertyDefinition[] | undefined;
    available: IPropertyDefinition[];
    options?: {
        // When in Mobile mode, the user can select multiple properties
        isMobile?: boolean;
        noUnselection?: boolean;
        multipleSelection?: boolean;
        chevron?: boolean;
        allOpen?: boolean;
        checkbox?: boolean;
        hideTitle?: boolean;
        disableBackButton?: boolean;
        atLeastOneSelected?: boolean;
        headerTitle?: string;
    };
    actionButton?: {
        icon: string;
        onClick: () => void;
    };
    selectProperty: (property: IPropertyDefinition[]) => void;
}

interface SidebarPropertiesMenuScope extends angular.IScope {
    model: ISidebarPropertiesMenuModel;
    toggle: ToggleModel;
    back: () => void;
    onSearch: (value: string) => void;
    propertyGroups: null | PropertyGroups;
    toggles: Record<string, ToggleModel>;
    propertyClick: ($event: Event, property: IPropertyDefinition) => void;
    listCustomHeaderOptions: IListCustomHeaderParamsOptions;
    multiPropertyModel: {
        mouseOnTopOfElement: boolean;
        enabled: boolean;
    };
    onActionButtonClick: ($event: Event) => void;
}

interface PropertyGroupsOptions {
    properties: IPropertyDefinition[];
    searchId: string;
    toggles?: Record<string, ToggleModel> | boolean | undefined;
    selectedId?: string[] | undefined;
    action: {
        onClick: (property: IPropertyDefinition) => void;
        onCheckBoxClick: (property: IPropertyDefinition) => void;
    };
    options?: {
        chevron?: boolean;
        disableButtonSelectionStateSwap?: boolean;
        checkbox?: boolean;
        hideTitle?: boolean;
        atLeastOneSelected?: boolean;
        shiftSelect?: boolean;
    };
}

export interface IPropertyGroupsProperty {
    id: string;
    label: string;
    selectable: boolean;
    selected: boolean;
    onClick: () => void;
    color: string;
    chevron: boolean;
    hideTitle?: boolean;
    checkbox: boolean;
    disableClick?: boolean;
    shiftSelect?: boolean;
    onCheckboxClick?: () => void;
}

class PropertyGroups {
    groups: { key: string; properties: IPropertyGroupsProperty[] }[];
    properties: IPropertyDefinition[];
    toggles: Record<string, ToggleModel>;
    selected: string[] = [];
    searchId: string;
    action: {
        onClick: (property: IPropertyDefinition) => void;
        onCheckBoxClick: (property: IPropertyDefinition) => void;
    };
    flags: {
        chevron: boolean;
        checkbox: boolean;
    };
    options: {
        chevron?: boolean;
        disableButtonSelectionStateSwap?: boolean;
        checkbox?: boolean;
        hideTitle?: boolean;
        atLeastOneSelected?: boolean;
        shiftSelect?: boolean;
    };
    constructor(options: PropertyGroupsOptions) {
        this.selected = options.selectedId ?? [];
        this.searchId = options.searchId;
        this.options = options.options ?? {};
        this.flags = {
            chevron: this.options.chevron ?? false,
            checkbox: this.options.checkbox ?? false,
        };
        this.properties = options.properties;
        this.groups = this.buildPropertyGroups(options.properties, options.searchId);
        this.toggles = this.buildToggles(options.toggles);
        this.action = options.action;
    }

    buildToggles(toggles: Record<string, ToggleModel> | boolean | undefined) {
        return this.groups.reduce<Record<string, ToggleModel>>((acc, group) => {
            const isOpen = (() => {
                if (typeof toggles === 'boolean') return toggles;
                return (
                    toggles?.[group.key]?.isActive ||
                    (Boolean(this.selected) && group.properties.some(property => this.selected.includes(property.id)))
                );
            })();
            acc[group.key] = new ToggleModel(isOpen);
            return acc;
        }, {});
    }

    buildPropertyGroups(properties: IPropertyDefinition[], searchId = '') {
        const groups = properties.reduce((acc: Record<string, IPropertyGroupsProperty[]>, property) => {
            const groupName = property.category?.label ?? property.label ?? property.table ?? '';

            const isSearchedGroup =
                searchId === '' ||
                groupName.toLowerCase().includes(searchId.toLowerCase()) ||
                (property.table ?? '').toLowerCase().includes(searchId.toLowerCase()) ||
                property.label.toLowerCase().includes(searchId.toLowerCase()) ||
                property.id.toLowerCase().includes(searchId.toLowerCase());

            if (!isSearchedGroup) return acc;

            acc[groupName] = acc[groupName] ?? [];
            const isSelected = this.selected.includes(property.id);
            const disableClick = (() => {
                return Boolean(this.options.atLeastOneSelected && isSelected && this.selected.length === 1);
            })();

            const hasCheckbox = this.options.checkbox ?? false;

            const propertyItem: IPropertyGroupsProperty = {
                id: property.id,
                label: property.label,
                selectable: true,
                checkbox: hasCheckbox,
                selected: isSelected,
                disableClick,
                ...(hasCheckbox
                    ? {
                          onCheckboxClick: () => {
                              if (this.selected.length === 1 && this.selected.includes(property.id)) return;
                              if (this.options.disableButtonSelectionStateSwap) {
                                  this.action.onCheckBoxClick(property);
                                  return;
                              }
                              propertyItem.selected = !propertyItem.selected;
                              this.action.onCheckBoxClick(property);
                          },
                      }
                    : {}),
                onClick: (event?: MouseEvent) => {
                    event?.preventDefault();
                    event?.stopImmediatePropagation();
                    if (this.options.disableButtonSelectionStateSwap) {
                        this.action.onClick(property);
                        return;
                    }

                    if (
                        this.options.atLeastOneSelected &&
                        this.selected.length === 1 &&
                        this.selected.includes(property.id)
                    )
                        return;

                    this.action.onClick(property);
                },
                color: 'blue',
                chevron: this.flags.chevron,
                shiftSelect: this.options.shiftSelect ?? false,
            };
            acc[groupName]?.push(propertyItem);

            return acc;
        }, {});

        const result = [];
        for (const [key, properties] of Object.entries(groups)) {
            result.push({ key, properties });
        }

        return result;
    }

    updateSelectedIds(selectedIds: string[]) {
        this.selected = selectedIds;
        for (const group of this.groups) {
            for (const property of group.properties) {
                property.selected = selectedIds.includes(property.id);
                property.disableClick = (() => {
                    return Boolean(this.options.atLeastOneSelected && property.selected && this.selected.length === 1);
                })();
            }
        }
    }
}

export const SidebarPropertiesMenuDirective = () => [
    function SidebarPropertiesMenu(): angular.IDirective<SidebarPropertiesMenuScope> {
        return {
            restrict: 'E',
            scope: {
                model: '=',
                toggle: '=',
            },
            replace: true,
            template: `
            <div class="sidebar-properties-menu">
                <list-custom-header-advanced-compact
                    model="model"
                    options="listCustomHeaderOptions"
                    toggle="toggle"
                    back="back">
                ></list-custom-header-advanced-compact>
                <article class="metrics-funnel-breadcrumb">
                    <div class="property-group"
                        ng-repeat="group in propertyGroups.groups"
                        ng-class="{
                            'opened': propertyGroups.toggles[group.key].isActive || propertyGroups.searchId !== '',
                            'multiple-selection': model.selected.length > 1,
                        }">
                        <div class="property-group-header" sticky
                            ng-click="propertyGroups.toggles[group.key].toggle()">
                            <div class="property-group-open-close">
                                <i class="icon-down-open"></i>
                            </div>
                            <div class="property-group-header-title">{{ group.key }}</div>
                        </div>
                        <div class="ui-pellets">
                            <selection-pebble
                                ng-repeat="x in group.properties"
                                model="x">
                            </selection-pebble>
                        </div>
                    </div>
                </article>
            </div>
            `,
            link: function SidebarPropertiesMenuLink($scope, element) {
                $scope.multiPropertyModel = {
                    mouseOnTopOfElement: false,
                    enabled: $scope.model.options?.multipleSelection ?? false,
                };

                $scope.listCustomHeaderOptions = {
                    label: $scope.model.options?.headerTitle ?? 'Rows By',
                    onSearch: (id: string) => $scope.onSearch(id),
                    hideTitle: Boolean($scope.model.options?.hideTitle),
                    ...(!$scope.model.options?.disableBackButton ? { back: () => $scope.toggle.close() } : {}),
                };

                if ($scope.model.options?.multipleSelection) {
                    const keyboardObserver = new KeyboardObserverAngular($scope, window);
                    keyboardObserver.onKeyPress((event: KeyboardEvent) => {
                        if ('key' in event && event.key !== 'Shift') {
                            $scope.multiPropertyModel.enabled = false;
                            return;
                        }
                        $scope.multiPropertyModel.enabled = event.type === 'keydown';
                    });

                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    const mouseElementObserver = new MouseObserverAngular($scope, element[0]!, {
                        preventDefault: false,
                    });
                    mouseElementObserver.onMouseEnter(() => {
                        $scope.$applyAsync(() => {
                            $scope.multiPropertyModel.mouseOnTopOfElement = true;
                        });
                    });
                    mouseElementObserver.onMouseLeave(() => {
                        $scope.$applyAsync(() => {
                            $scope.multiPropertyModel.mouseOnTopOfElement = false;
                        });
                    });
                }

                const resetSelected = () => {
                    $scope.model.selected = [];
                    $scope.propertyGroups?.updateSelectedIds([]);
                    $scope.model.selectProperty([]);
                };

                const selectProperty = (property: IPropertyDefinition) => {
                    // Put condition for when pressing the same property and it's the only one in the list

                    if ($scope.model.options?.isMobile) {
                        $scope.model.selectProperty([property]);
                        return;
                    }

                    const isOnlyOneAndDeselection =
                        $scope.model.selected?.length === 1 &&
                        $scope.model.selected[0] &&
                        $scope.model.selected[0].id === property.id;

                    if ($scope.model.options?.atLeastOneSelected && isOnlyOneAndDeselection) return;

                    if (!$scope.model.options?.atLeastOneSelected && isOnlyOneAndDeselection) {
                        resetSelected();
                        return;
                    }

                    $scope.model.selected = [property];
                    $scope.propertyGroups?.updateSelectedIds($scope.model.selected.map(x => x.id));
                    $scope.model.selectProperty($scope.model.selected);
                };

                const multiPropertyClick = (property: IPropertyDefinition) => {
                    if (
                        $scope.model.selected?.length === 1 &&
                        $scope.model.selected[0] &&
                        $scope.model.selected[0].id === property.id
                    )
                        return;

                    if (!$scope.model.selected?.find(s => s.id === property.id)) {
                        $scope.model.selected = [...($scope.model.selected ?? []), property];
                    } else {
                        $scope.model.selected = $scope.model.selected.filter(s => s.id !== property.id);
                    }

                    $scope.propertyGroups?.updateSelectedIds($scope.model.selected.map(x => x.id));

                    if ($scope.model.options?.isMobile) {
                        $scope.model.selectProperty([property]);
                    } else {
                        $scope.model.selectProperty($scope.model.selected);
                    }
                };

                const buildPropertyGroups = (config?: {
                    toggles: undefined | boolean | Record<string, ToggleModel>;
                }) => {
                    const { chevron, isMobile, multipleSelection, checkbox, hideTitle, atLeastOneSelected } =
                        $scope.model.options ?? {};

                    return new PropertyGroups({
                        properties: $scope.model.available,
                        searchId,
                        selectedId: $scope.model.selected ? getSelectedId($scope.model.selected) : [],
                        toggles: config?.toggles ?? $scope.propertyGroups?.toggles,
                        action: {
                            onClick: selectProperty,
                            onCheckBoxClick: multiPropertyClick,
                        },
                        options: {
                            disableButtonSelectionStateSwap: Boolean(isMobile),
                            chevron: Boolean(chevron),
                            checkbox: Boolean(multipleSelection && checkbox !== false),
                            hideTitle: Boolean(hideTitle),
                            atLeastOneSelected: Boolean(atLeastOneSelected),
                            shiftSelect: Boolean(multipleSelection && checkbox !== false),
                        },
                    });
                };

                let searchId = '';
                let unFilteredToggles: Record<string, ToggleModel> | undefined;
                $scope.onSearch = (id: string) => {
                    const toggles = (() => {
                        if (id !== '') {
                            if (!unFilteredToggles) {
                                unFilteredToggles ??= _.cloneDeep($scope.propertyGroups?.toggles);
                            }
                            return $scope.propertyGroups?.toggles;
                        }

                        const toggles = _.cloneDeep(unFilteredToggles ?? $scope.propertyGroups?.toggles);
                        unFilteredToggles = undefined;
                        return toggles;
                    })();

                    searchId = id;
                    $scope.propertyGroups = buildPropertyGroups({ toggles });
                };

                $scope.$watch('toggle.isActive', (isActive: boolean) => {
                    if (isActive) searchId = '';
                });

                const getSelectedId = (selected: IPropertyDefinition[] | IPropertyDefinition) => {
                    const selectedId = Array.isArray(selected) ? selected : [selected];
                    return selectedId.map(x => x.id);
                };

                let unWatchAvailableProperties = () => {};
                $scope.$watch('model', () => {
                    unWatchAvailableProperties();
                    $scope.propertyGroups = null;
                    unWatchAvailableProperties = $scope.$watch(
                        'model.available',
                        (
                            available: IPropertyDefinition[] | undefined,
                            old: IPropertyDefinition[] | undefined,
                        ): void => {
                            if (!available) return;
                            const toggles = (() => {
                                if (old === available && typeof $scope.model.options?.allOpen === 'boolean') {
                                    return $scope.model.options.allOpen;
                                }
                                return $scope.propertyGroups?.toggles ?? {};
                            })();

                            $scope.propertyGroups = buildPropertyGroups({ toggles });
                        },
                    );
                });
            },
        };
    },
];

interface ISidebarMetricsMenuModel {
    selected: string[];
    available: IMetricDefinition[];
    options: ICreateMetricSelectorTreeParamsOptions;
    selectMetrics: (selected: IMetricDefinition[]) => void;
}

interface SidebarMetricsMenuScope extends angular.IScope {
    model: ISidebarMetricsMenuModel;
    toggle: ToggleModel;
    listCustomHeaderOptions: IListCustomHeaderOptions;
    categoriesMode: {
        messages: string[];
        toggle: ToggleModel;
    };
    onBackClick: () => void;
}

export const SidebarMetricsMenuDirective = () => [
    function SidebarMetricsMenu(): angular.IDirective<SidebarMetricsMenuScope> {
        return {
            restrict: 'E',
            scope: {
                model: '=',
                toggle: '=',
            },
            replace: true,
            template: `
                <div class="sidebar-metrics-menu">
                    <list-custom-header-advanced
                        model="model"
                        options="listCustomHeaderOptions"
                        toggle="toggle"
                        back="onBackClick"
                    ></list-custom-header-advanced>
                    <main>
                        <div class="metric-selector-container">
                            <div class="metric-selector-tree"></div>
                        </div>
                    </main>
                </div>
            `,
            link: function SidebarMetricsMenuLink($scope, $element) {
                const $metricSelectElement = $($element).find('.metric-selector-tree');
                let metricSelect: null | IMetricSelectorTreeInstance = null;

                $scope.categoriesMode = {
                    toggle: new ToggleModel(),
                    messages: ['Group by Categories'],
                };

                const actions = (() => {
                    if ($scope.model.options.hideCategories) return [];
                    return [$scope.categoriesMode];
                })();
                $scope.listCustomHeaderOptions = {
                    itemLabel: 'Edit Metrics',
                    onSearch: (value: string) => {
                        metricSelect?.showJsTreeIfHidden();
                        metricSelect?.setSearch(value);
                    },
                    selectAll: () => {
                        if (!metricSelect) return;
                        const visibleNodes = metricSelect.getVisibleNodes();
                        if (visibleNodes.length === $scope.model.available.length) {
                            metricSelect.selectAll();
                            onChangeSelectedMetrics();
                            return;
                        }

                        metricSelect.selectNodes(visibleNodes);
                        onChangeSelectedMetrics();
                    },
                    reset: () => {
                        metricSelect?.unselectAll();
                        onChangeSelectedMetrics();
                    },
                    actions,
                };

                $scope.$watch('categoriesMode.toggle.isOpen', (old, newState) => {
                    if (old === newState) return;
                    reload();
                });

                const onChangeSelectedMetrics = () => {
                    const metrics = metricSelect?.getSelected() ?? [];
                    if (_.isEqual(metrics, $scope.model.selected)) return;
                    let selectedMetrics = $scope.model.available.filter(x => metrics.includes(x.field));
                    if (metrics.length === $scope.model.selected.length && $scope.categoriesMode.toggle.isOpen) return;

                    const metricsOrder: string[] = (() => {
                        if (!$scope.categoriesMode.toggle.isOpen) return metrics;
                        return metricSelect?.getUncategorizedMetricGroups() ?? [];
                    })();

                    selectedMetrics = _.sortBy(selectedMetrics, x => metricsOrder.indexOf(x.field));
                    $scope.model.selectMetrics(selectedMetrics);
                };

                const reload = () => {
                    metricSelect?.destroy();
                    metricSelect = createMetricSelect({
                        element: $metricSelectElement,
                        model: _.cloneDeep({ selected: $scope.model.selected, available: $scope.model.available }),
                        options: {
                            ...$scope.model.options,
                            ...{
                                hideCategories: !$scope.categoriesMode.toggle.isOpen,
                            },
                        },
                        onChange: () => onChangeSelectedMetrics(),
                        onSearch: (value, node) => {
                            if (metricSelect?.isLeaf(node) && node.parent !== '#' && node.parents.length > 2)
                                return false;

                            return IncludesFilter.parse(node.text, value, false).length > 0;
                        },
                    });
                };

                $scope.onBackClick = () => {
                    $scope.toggle.close();
                };

                $scope.$watch('model.selected', () => {
                    if (metricSelect) {
                        const selectedIds = metricSelect.getSelected();
                        if (_.isEqual(selectedIds, $scope.model.selected)) return;

                        const openedNodes = metricSelect.getAllOpenedNodes();
                        reload();
                        metricSelect.openNodes(openedNodes);
                    }
                });

                $scope.$watch('toggle.isActive', (isActive: boolean) => {
                    if (isActive) reload();
                });
                $scope.$on('$destroy', () => metricSelect?.destroy());
            },
        };
    },
];

interface SidebarModelOptions {
    hideTabs?: boolean;
    showMenuBar?: boolean;
    disableResize?: boolean;
    unSelection?: boolean;
}

interface SidebarModelArguments {
    toggle?: undefined | SidebarToggleModel;
    properties?: ISidebarPropertiesMenuModel;
    displayBy?: ISidebarPropertiesMenuModel;
    metrics?: ISidebarMetricsMenuModel;
    options?: SidebarModelOptions;
    propertiesTabs?: (ISidebarPropertiesMenuModel & { id: string })[];
}

export class SidebarModel {
    toggle: SidebarToggleModel;
    properties: ISidebarPropertiesMenuModel | undefined;
    propertiesTabs: (ISidebarPropertiesMenuModel & { id: string })[];
    displayBy: undefined | ISidebarPropertiesMenuModel;
    metrics: undefined | ISidebarMetricsMenuModel;
    options?: undefined | SidebarModelOptions;

    constructor({ toggle, properties, propertiesTabs, metrics, displayBy, options }: SidebarModelArguments) {
        if (!properties && (!Array.isArray(propertiesTabs) || propertiesTabs.length === 0) && !metrics && !displayBy) {
            throw new Error('[sidebar] At least one of type of tab must be defined');
        }

        this.properties = properties;
        this.propertiesTabs = propertiesTabs ?? [];
        this.displayBy = displayBy;
        this.metrics = metrics;
        this.options = options;
        this.toggle = (() => {
            if (toggle) return toggle;

            const tab = (() => {
                if (this.properties) return 'properties';
                const property = this.propertiesTabs[0];
                if (property) return property.id;
                if (metrics) return 'metrics';
                return 'displayBy';
            })();

            return new SidebarToggleModel({ tab, isOpen: true });
        })();
    }
}

interface SidebarDirectiveScope extends angular.IScope {
    model: SidebarModel;
    toggle: SidebarToggleModel;
    resizing: boolean;
    selectTab: (event: Event, tab: string) => void;
    toggleSidebar: (event: Event) => void;
    initResizeMode: () => void;
}
export const SidebarDirective = () => [
    '$document',
    function SidebarDirective($document: angular.IDocumentService): angular.IDirective<SidebarDirectiveScope> {
        return {
            restrict: 'E',
            scope: {
                model: '=',
                toggle: '=',
            },
            replace: true,
            template: `
                <div class="dimensions-sidebar"
                    ng-class="{'closed': !model.toggle.isActive, 'light': model.toggle.tab !== 'properties'}">
                    <div class="dimensions-sidebar-bar" ng-if="model.options.showMenuBar">
                        <div class="sidebar-header-close" ng-click="toggleSidebar($event)">
                            <div class="sidebar-toggle-icon">
                                <i class="icon-left-open"></i>
                            </div>
                        </div>
                    </div>
                    <div class="dimensions-sidebar-content">
                        <div class="sidebar-header" ng-if="!model.options.hideTabs">
                            <div class="sidebar-header-tabs">
                                <div
                                    ng-repeat="tab in TABS"
                                    ng-if="model[tab.id] && tab.id === model.toggle.tab"
                                    ng-click="selectTab(tab.id)"
                                    class="sidebar-header-tab"
                                    ng-class="{'active': tab.id === model.toggle.tab}">
                                    <div class="sidebar-header-title">{{ tab.label }}</div>
                                </div>
                            </div>
                        </div>
                        <sidebar-properties-menu
                            ng-repeat="tab in model.propertiesTabs"
                            model="tab"
                            ng-if="model.toggle.tab === tab.id"
                            toggle="model.toggle">
                        </sidebar-properties-menu>
                        <sidebar-properties-menu
                            ng-if="model.properties && model.toggle.tab === 'properties'"
                            model="model.properties"
                            toggle="model.toggle">
                        </sidebar-properties-menu>
                        <sidebar-properties-menu
                            ng-if="model.displayBy && model.toggle.tab === 'displayBy'"
                            model="model.displayBy"
                            toggle="model.toggle">
                        </sidebar-properties-menu>
                        <sidebar-metrics-menu
                            ng-if="model.metrics && model.toggle.tab === 'metrics'"
                            model="model.metrics"
                            toggle="model.toggle">
                        </sidebar-metrics-menu>
                    </div>
                    <div
                        class="sidebar-resizer-bar"
                        ng-if="!model.options.disableResize"
                        ng-class="{ 'active': resizing }"
                        ng-mousedown="initResizeMode($event)">
                    </div>
                </div>
            `,
            link: function sidebarLink($scope, $element) {
                const RE_SIZING_CLASS = 'resizing';
                const $app = $document.find('#app');
                const $dimensionsSidebarBarElement = $element.find('.dimensions-sidebar-bar');
                const $dimensionsSidebarContentElement = $element.find('.dimensions-sidebar-content');

                const endResizeMode = () => {
                    $app.removeClass(RE_SIZING_CLASS);
                    $scope.resizing = false;
                    $document.off<'mousemove'>('mousemove', onResizeMove);
                    $document.off('mouseup', endResizeMode);
                };
                const onResizeMove = _.throttle((event: JQuery.Event) => {
                    if (!$dimensionsSidebarContentElement[0]) return;
                    const { left, width } = $dimensionsSidebarContentElement[0].getBoundingClientRect();
                    const { width: sidebarBarWidth } = $dimensionsSidebarBarElement[0]?.getBoundingClientRect() ?? {
                        width: 0,
                    };
                    const { clientX, clientY } = event;
                    if (clientY === undefined || clientX === undefined) {
                        endResizeMode();
                        return;
                    }

                    const newWidth = clientX - left;
                    if (newWidth < 272) {
                        if (width !== 272) {
                            $element.css({ width: `${sidebarBarWidth + 272}px` });
                        }
                        return;
                    }
                    $element.css({ width: `${sidebarBarWidth + newWidth}px` });
                }, 10);

                $scope.initResizeMode = () => {
                    if (!$app.hasClass(RE_SIZING_CLASS)) $app.addClass(RE_SIZING_CLASS);
                    $scope.resizing = true;
                    $document.on('mousemove', onResizeMove);
                    $document.on('mouseup', endResizeMode);
                };

                $scope.toggleSidebar = (event: Event) => {
                    const tab = (() => {
                        if ($scope.model.toggle.isActive) return undefined;
                        return !_.isNil($scope.model.properties) ? 'properties' : 'metrics';
                    })();
                    $scope.model.toggle.toggle(event, tab);
                };

                $scope.selectTab = (event: Event, tabId: string) => {
                    const tab = $scope.model.toggle.tab !== tabId ? tabId : undefined;
                    $scope.model.toggle.toggle(event, tab);
                };

                $scope.$on('$destroy', () => {
                    if ($app.hasClass(RE_SIZING_CLASS)) $app.removeClass(RE_SIZING_CLASS);
                });
            },
        };
    },
];

const SidebarModule = angular
    .module('42.components.sidebar', [])
    .directive('sidebar', SidebarDirective())
    .directive('sidebarPropertiesMenu', SidebarPropertiesMenuDirective())
    .directive('sidebarMetricsMenu', SidebarMetricsMenuDirective());

export default SidebarModule;

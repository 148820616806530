import _ from 'lodash';
import { titleize } from 'inflected';
import { IPromise } from 'angular';
import * as AgGrid from '@ag-grid-community/core';
import * as Analytics from '../../lib/analytics';
import * as Auth from '../../lib/auth';
import { IQuery, IMetricDefinition } from '../../lib/types';
import { FontWidthCalculator } from '../../lib/dom/font-width-calculator';
import {
    IMetricsGridConfigViewsColumns,
    isLoadingTotalRow,
    isTotalRow,
    mapRowsAsColumns,
    mapTotalRowsAsColumns,
} from './metrics-utils';
import { FilterExpressionParser } from '../../lib/parsers/filter-expression-parser';
import type { AngularInjected } from '../../lib/angular';
import { deepStripAngularProperties } from '../../lib/angular';
import { purifyText } from '../../lib/dom/html';
import type { DashboardRootScope } from '../main-controller';
import { GridOverlayMessageRendererFactory } from '../../components/grid-overlay-message-renderer';
import { IColumnDef, MetricsFunnel, IMetricsFunnelNodeService, MetricsFunnelNode } from './metrics-funnel';
import { convertToAGGridFiltering, getPercentFilterValue, IAGGridFiltering } from './metrics-grid-utils';
import { AuthUserModel } from '@42technologies/client-lib-auth';
import { ConfigPageExperiments } from '../../lib/config-experiments';
import { ActionsPanelSimpleItem } from '../../components/actions-panel';
import { isObject } from '../../lib/utils';
import { FUNNEL_SVG } from '../../lib/svg-icons/funnel.svg';

interface IMetricsKPIsService {
    fetch: (query: IQuery) => Promise<(IMetricDefinition & { _cellClass?: string })[]>;
}

interface IMetricsGridSort {
    field: string;
    order: 1 | -1;
}

type IMetricsPageGridDataRow = Record<string, unknown>;

export interface IMetricsFunnelRowData {
    rows: IMetricsPageGridDataRow[];
    total: IMetricsPageGridDataRow[];
}

const GRID_HEADER_ROW_HEIGHT = 40;
const GRID_DATA_ROW_HEIGHT = 60;
const GRID_DATA_ROW_HEIGHT__WITH_IMAGE = 85;

const EMPTY_TOTAL_ROW = [{ value0: '$total', property0: '$total' }];

const getEmptyTotalRow = () => _.cloneDeep(EMPTY_TOTAL_ROW);

class MetricsGridData {
    rows: IMetricsPageGridDataRow[] = [];
    total: IMetricsPageGridDataRow[] = [];

    constructor(data?: IMetricsFunnelRowData) {
        const { rows, total } = data ?? {
            rows: [],
            total: getEmptyTotalRow(),
        };
        this.rows = rows;
        this.total = total;
    }

    getRows() {
        return _.cloneDeep(this.rows);
    }

    getTotal() {
        if (this.total.length === 0) return getEmptyTotalRow();
        return _.cloneDeep(this.total);
    }

    getRowsAndTotal(): IMetricsFunnelRowData {
        return _.cloneDeep({
            rows: this.rows,
            total: this.total,
        });
    }
}

interface IMetricsFunnelNodeViewDirectiveScope extends angular.IScope {
    user: AuthUserModel;
    tabName: string;
    organization: string;
    grid: IMetricsFunnelNodeGridViewModel;
    model: MetricsFunnel;
    experiments: ConfigPageExperiments;
    metricsGridDataModel?: IMetricsGridDataModel;

    drilldownModel: {
        active: boolean;
        enabled: boolean;
        selectedValuesByProperty: Record<string, Record<string, (string | number)[]> | undefined>;
    };

    onColumnResize: (columnsResized: Record<string, number>) => void;
    onRowSortChange: (sort: IMetricsGridSort[]) => void;
    onFilterChanged: (columnFilters: IAGGridFiltering) => void;

    onRowSelectionChange: (rows: Record<string, Record<string, (string | number)[]> | undefined>) => void;

    drilldownButtonModel: ActionsPanelSimpleItem;
    clearFiltersButtonModel: ActionsPanelSimpleItem;

    select: (value: string) => void;

    export: () => IPromise<void> | undefined;
    /** @deprecated we need to get rid of this thing... */
    exportSetter: (fn: () => IPromise<void> | undefined) => void;
}

function MetricsGridDataModelsFactory(
    $rootScope: DashboardRootScope,
    $q: angular.IQService,
    MetricsFunnelNodeService: IMetricsFunnelNodeService,
) {
    class MetricsTotalGridData {
        data: IMetricsPageGridDataRow[] | null = null;
        funnel: MetricsFunnel | null = null;
        actions: {
            onError: (error: Error) => void;
        };

        constructor(params: {
            funnel?: MetricsFunnel | null;
            total: IMetricsPageGridDataRow[] | null;
            actions: {
                onError: (error: Error) => void;
            };
        }) {
            this.data = params.total;
            this.funnel = params.funnel ?? null;
            this.actions = params.actions;
            if (!this.funnel) return;
            void this.init();
        }

        protected fetchTotal() {
            if (!this.funnel) return $q.resolve(undefined);
            const filters = this.funnel.node.getFiltering();
            if (_.isEmpty(filters)) return $q.resolve(undefined);
            return Auth.getOrganization().then(organizationId => {
                if (organizationId.startsWith('sportsdirect') || !this.funnel) return $q.resolve(undefined);

                const query = MetricsFunnelNodeService.toQuery({
                    node: this.funnel.node,
                    query: $rootScope.query ?? {},
                    metricsFiltering: filters,
                });

                return MetricsFunnelNodeService.fetch(this.funnel.node, query);
            });
        }

        protected init() {
            return this.fetchTotal()
                .then(response => {
                    this.data = (() => {
                        if (response?.total && response.total.length > 0) return response.total;
                        return getEmptyTotalRow();
                    })();
                })
                .catch(error => {
                    if (error instanceof Error) return this.actions.onError(error);
                    throw error;
                });
        }

        getTotal() {
            return _.cloneDeep(this.data);
        }
    }

    class MetricsGridDataModel {
        funnel: MetricsFunnel;
        data: MetricsGridData | null = null;
        total: MetricsTotalGridData | null = null;
        organization: string;
        actions: {
            onError: (error: Error) => void;
        };

        constructor(params: {
            funnel: MetricsFunnel;
            organization: string;
            data?: IMetricsFunnelRowData | null;
            actions: {
                onError: (error: Error) => void;
            };
        }) {
            this.funnel = params.funnel;
            this.organization = params.organization;
            this.actions = params.actions;
            void this.init(params.data ?? null);
        }

        private fetchDataWithMetricFilters() {
            const filters = this.funnel.node.getFiltering();
            if (_.isEmpty(filters) || this.organization.startsWith('sportsdirect')) return $q.resolve(undefined);
            const query = MetricsFunnelNodeService.toQuery({
                node: this.funnel.node,
                query: $rootScope.query ?? {},
                metricsFiltering: filters,
            });
            return MetricsFunnelNodeService.fetch(this.funnel.node, query);
        }

        private fetchAllData(data?: IMetricsFunnelRowData | null): IPromise<IMetricsFunnelRowData> {
            if (data) return $q.resolve(data);
            const query = MetricsFunnelNodeService.toQuery({
                node: this.funnel.node,
                query: $rootScope.query ?? {},
            });
            return MetricsFunnelNodeService.fetch(this.funnel.node, query);
        }

        private init(data?: IMetricsFunnelRowData | null) {
            return $q
                .all([this.fetchAllData(data), this.fetchDataWithMetricFilters()])
                .then(response => {
                    const [allData, filteredData] = response;

                    // NO Metric filters applied
                    this.data = new MetricsGridData(allData);
                    if (!filteredData) {
                        this.total = new MetricsTotalGridData({
                            total: this.data.getTotal(),
                            actions: this.actions,
                        });
                        return;
                    }

                    // Empty response
                    if (this.data.rows.length === 0) {
                        this.total = new MetricsTotalGridData({
                            total: getEmptyTotalRow(),
                            actions: this.actions,
                        });
                        return;
                    }

                    this.total = new MetricsTotalGridData({
                        total: filteredData.total.length !== 0 ? filteredData.total : getEmptyTotalRow(),
                        actions: this.actions,
                    });
                })
                .catch((error: Error) => {
                    this.data = new MetricsGridData();
                    this.actions.onError(error);
                });
        }

        updateTotal(numberOfDisplayedRows?: number) {
            const filters = this.funnel.node.getFiltering();

            // NO Rows displayed - meaning all data was filtered out
            if (numberOfDisplayedRows === 0) {
                this.total = new MetricsTotalGridData({
                    actions: this.actions,
                    total: getEmptyTotalRow(),
                });
                return;
            }

            if (!this.data) return;

            // NO Metric filters applied or SportsDirect organization
            if (_.isEmpty(filters) || this.organization.startsWith('sportsdirect')) {
                this.total = new MetricsTotalGridData({
                    actions: this.actions,
                    total: this.data.getTotal(),
                });
                return;
            }

            this.total = new MetricsTotalGridData({
                actions: this.actions,
                funnel: this.funnel,
                total: [{ value0: '$total', property0: '$loadingTotal' }],
            });
        }
    }
    return {
        MetricsGridDataModel,
        MetricsTotalGridData,
    };
}
type IMetricsGridDataModel = InstanceType<ReturnType<typeof MetricsGridDataModelsFactory>['MetricsGridDataModel']>;

export const MetricsFunnelNodePropertyViewDirectiveInstance = () => [
    '$rootScope',
    '$q',
    'MetricsFunnelNodeService',
    'MetricsFunnelNodeGridViewModel',
    'MetricsKPIsService',
    function MetricsFunnelNodeViewDirective(
        $rootScope: DashboardRootScope,
        $q: angular.IQService,
        MetricsFunnelNodeService: IMetricsFunnelNodeService,
        MetricsFunnelNodeGridViewModel: IMetricsFunnelNodeGridViewModelFactory,
        MetricsKPIsService: IMetricsKPIsService,
    ): angular.IDirective<IMetricsFunnelNodeViewDirectiveScope> {
        const { MetricsGridDataModel } = MetricsGridDataModelsFactory($rootScope, $q, MetricsFunnelNodeService);
        return {
            restrict: 'E',
            scope: {
                user: '=',
                tabName: '=',
                model: '=',
                exportSetter: '=',
                organization: '=',
                experiments: '=',
            },
            replace: true,
            template: `
                <article class="metrics-funnel-node">
                    <div class="grid-actions-panel">
                        <actions-panel-simple-item item="drilldownButtonModel"></actions-panel-simple-item>
                        <actions-panel-simple-item item="clearFiltersButtonModel"></actions-panel-simple-item>
                    </div>
                    <div class="ag-42 grid grid-new ag-theme-alpine"
                        ng-if="grid.options"
                        ag-grid="grid.options"
                    ></div>
                </article>
            `,
            link: function MetricsFunnelNodeViewDirectiveLink(scope) {
                scope.grid = MetricsFunnelNodeGridViewModel(scope);

                scope.onRowSelectionChange = (
                    rows: Record<string, Record<string, (string | number)[]> | undefined>,
                ) => {
                    view.enableDrilldownButton = !_.isEmpty(rows);
                };

                const view = {
                    enableDrilldownButton: false,
                    enableClearFiltersButton: false,
                };

                scope.drilldownModel = {
                    enabled: false,
                    active: false,
                    selectedValuesByProperty: {},
                };

                scope.drilldownButtonModel = new ActionsPanelSimpleItem({
                    label: 'Drilldown',
                    svg: FUNNEL_SVG,
                    isDisabled: () => !view.enableDrilldownButton,
                    disabledTooltip: 'No rows selected',
                    onClick: () => selectMultiplePropertiesDrilldown(),
                });

                scope.clearFiltersButtonModel = new ActionsPanelSimpleItem({
                    label: 'Clear Grid Filters',
                    icon: { type: 'icon-trash' },
                    isDisabled: () => !view.enableClearFiltersButton,
                    onClick: () => clearFilters(),
                });

                const selectMultiplePropertiesDrilldown = () => {
                    if (_.isEmpty(scope.drilldownModel.selectedValuesByProperty)) return;
                    const rowDrilldownValues = _.compact(Object.values(scope.drilldownModel.selectedValuesByProperty));
                    if (_.isEmpty(rowDrilldownValues)) return;
                    const values = rowDrilldownValues.reduce<Record<string, Set<string | number>>>((acc, rowValues) => {
                        for (const [key, value] of Object.entries(rowValues)) {
                            acc[key] ??= new Set();
                            value.forEach(v => acc[key]?.add(v));
                        }
                        return acc;
                    }, {});
                    const drilldown = Object.entries(values).reduce<Record<string, (string | number)[]>>(
                        (acc, [key, value]) => {
                            acc[key] = Array.from(value);
                            return acc;
                        },
                        {},
                    );

                    scope.model.drilldown(drilldown);
                    scope.drilldownModel.selectedValuesByProperty = {};
                    view.enableDrilldownButton = false;
                };

                const isProperty = (field: string) => /^property\d+/.test(field);
                const isItemMetric = (field: string) => field.startsWith('item_');
                const onError = (error: Error) => {
                    view.enableClearFiltersButton = !_.isEmpty(scope.model.node.getFiltering());
                    Analytics.logError(new Error('[metrics funnel] [refresh] error', { cause: error }));
                    scope.grid.setError(true);
                    throw error;
                };
                let watchFiltering: () => void = () => {};
                // let watchColumnProperties: () => void = () => {};
                const refresh = () => {
                    watchFiltering();
                    // watchColumnProperties();
                    scope.grid.setError(false);
                    scope.metricsGridDataModel = new MetricsGridDataModel({
                        funnel: scope.model,
                        organization: scope.organization,
                        actions: { onError },
                    });
                    scope.grid.options.api?.showLoadingOverlay();

                    watchFiltering = scope.$watch('model.node.filtering', () => {
                        if (!scope.metricsGridDataModel?.data) return;
                        scope.grid.setError(false);

                        const numberOfDisplayedRows = scope.grid.options.api?.getRenderedNodes().length ?? 0;
                        scope.metricsGridDataModel.updateTotal(numberOfDisplayedRows);
                    });

                    // watchColumnProperties = scope.$watch('model.node.columnProperties', () => {
                    //     if (!scope.metricsGridDataModel?.data) return;
                    //     refresh();
                    // });
                };

                const updateGridData = () => {
                    if (!scope.metricsGridDataModel) return;
                    scope.grid.updateData({
                        node: scope.model.node,
                        data: scope.metricsGridDataModel.data?.getRowsAndTotal() ?? null,
                        widths: scope.model.views.columns,
                        filters: convertToAGGridFiltering(
                            scope.model.node.getFiltering(),
                            scope.model.metrics.availableByField,
                        ),
                    });
                    view.enableClearFiltersButton = !_.isEmpty(scope.model.node.getFiltering());
                };

                scope.$watch('metricsGridDataModel.data', () => updateGridData());
                scope.$watch('metricsGridDataModel.funnel.node', () => {
                    if (scope.metricsGridDataModel && !scope.metricsGridDataModel.data) updateGridData();
                    view.enableDrilldownButton = false;
                });

                scope.$watch('metricsGridDataModel.total.data', () => {
                    if (!scope.metricsGridDataModel) return;

                    const totalRows = (() => {
                        const hasMultipleProperties = scope.model.node.property.length > 1;
                        const hasColumnsAsProperties = scope.model.node.columnProperties.length > 0;

                        const total = scope.metricsGridDataModel.total?.getTotal();
                        if (!total) return getEmptyTotalRow();

                        if (hasColumnsAsProperties) return total;
                        if (hasMultipleProperties) return total.slice(0, 1);

                        return total;
                    })();

                    scope.grid.updateTotalRowData(totalRows, scope.model.node);
                });

                const clearFilters = () => {
                    if (!view.enableClearFiltersButton) return;
                    scope.grid.options.api?.setFilterModel(null);
                    view.enableClearFiltersButton = false;
                };

                scope.onColumnResize = (columnsResized: Record<string, number>) => {
                    scope.model.updateGridConfigColumnWidth(columnsResized);
                };
                scope.onRowSortChange = (sortedColumns: IMetricsGridSort[]) => {
                    scope.model.updateSort(sortedColumns);
                };

                scope.onFilterChanged = _.debounce((columnFilters: IAGGridFiltering) => {
                    const metricsByField = _.keyBy(scope.model.metrics.selected, 'field');
                    // when the cellFilter is percentage, the `numberParser` picks the filter value and does this transformation FilterExpressionParser.percent(Number(text))
                    // so we need to multiply the filter value by 100 to get the correct value to save, otherwise it would change the value the user inputs
                    for (const [key, columnFilter] of Object.entries(columnFilters)) {
                        if (isProperty(key)) continue;
                        const metric = metricsByField[key];
                        if (!metric) throw new Error(`Metric ${key} not found`);
                        if (metric.cellFilter?.startsWith('percent')) {
                            if ('filter' in columnFilter && typeof columnFilter.filter === 'number') {
                                columnFilter.filter = getPercentFilterValue(columnFilter.filter);
                            }
                            if ('condition1' in columnFilter && typeof columnFilter.condition1.filter === 'number') {
                                columnFilter.condition1.filter = getPercentFilterValue(columnFilter.condition1.filter);
                            }
                            if ('condition2' in columnFilter && typeof columnFilter.condition2.filter === 'number') {
                                columnFilter.condition2.filter = getPercentFilterValue(columnFilter.condition2.filter);
                            }
                        }
                    }

                    if (
                        _.isEqual(
                            convertToAGGridFiltering(
                                scope.model.node.getFiltering(),
                                scope.model.metrics.availableByField,
                            ),
                            columnFilters,
                        )
                    )
                        return;
                    view.enableClearFiltersButton = !_.isEmpty(columnFilters);
                    scope.model.updateGridColumnFilters(columnFilters);
                }, 100);

                scope.exportSetter(() => {
                    const query = _.cloneDeep($rootScope.query ?? {});
                    return $q.when(MetricsKPIsService.fetch(query)).then(metrics => {
                        const metricsByField = Object.fromEntries(metrics.map(x => [x.field, x]));

                        const associatedProperties = MetricsFunnelNodeService.getAssociatedProperties(scope.model.node);
                        const itemMetricProperties = metrics
                            .filter(x => isItemMetric(x.field))
                            .map(x => x.field.replace(/^item_/, 'items.'));

                        const metricColumnDefs = scope.grid.getColumnDefs(scope.model.node, null).flatMap(x => {
                            const field = x.field;
                            if (typeof field !== 'string') return [];
                            if (isProperty(field) || field === 'item_image__left') return [];
                            x = deepStripAngularProperties(x);
                            // FIXME: why not use the x._cellClass?
                            const metricDef = metricsByField[field];
                            if (metricDef?._cellClass) x.cellClass = metricDef._cellClass;
                            delete x._cellClass;
                            delete x.cellRenderer;
                            delete x.drilldown;
                            delete x.filter;
                            delete x.columnViewName;
                            delete x.category;
                            return [x];
                        }, []);

                        query.options = {
                            ...(query.options ?? {}),
                            associatedProperties: [...associatedProperties, ...itemMetricProperties],
                            metrics: _.compact(metricColumnDefs.map(columnDef => columnDef.field)),
                        };

                        query.export = {
                            properties: deepStripAngularProperties(scope.model.properties),
                            columnDefs: metricColumnDefs,
                        };

                        return MetricsFunnelNodeService.runExport({
                            query,
                            node: scope.model.node,
                            tabName: scope.tabName,
                            metricsByField,
                        });
                    });
                });

                scope.$watch('grid.columnSortOrder', (columnSortOrder: string[]) => {
                    scope.model.updateColumnSortOrder(columnSortOrder);
                });

                scope.$watch(
                    'model.metrics.selected',
                    (selectedMetrics: IColumnDef[]) => {
                        const gridMetrics = scope.grid.columnSortOrder;
                        const modelMetrics = selectedMetrics.map(x => x.field);
                        if (_.isEqual(gridMetrics, modelMetrics)) return;
                        scope.grid.updateColumns({
                            node: scope.model.node,
                            columns: scope.model.metrics.selected,
                            widths: scope.model.views.columns ?? {},
                            rows: scope.grid.getRows(),
                        });
                    },
                    true,
                );

                scope.$watch('model.node', () => {
                    console.log('model.mode node node node node');
                    refresh();
                });
                scope.$on(
                    '$destroy',
                    $rootScope.$on('query.refresh', () => refresh()),
                );
            },
        };
    },
];

export const MetricsFunnelNodeViewDirectiveInstance = () => [
    '$rootScope',
    '$q',
    'MetricsFunnelNodeService',
    'MetricsFunnelNodeGridViewModel',
    'MetricsKPIsService',
    function MetricsFunnelNodeViewDirective(
        $rootScope: DashboardRootScope,
        $q: angular.IQService,
        MetricsFunnelNodeService: IMetricsFunnelNodeService,
        MetricsFunnelNodeGridViewModel: IMetricsFunnelNodeGridViewModelFactory,
        MetricsKPIsService: IMetricsKPIsService,
    ): angular.IDirective<IMetricsFunnelNodeViewDirectiveScope> {
        const { MetricsGridDataModel } = MetricsGridDataModelsFactory($rootScope, $q, MetricsFunnelNodeService);
        return {
            restrict: 'E',
            scope: {
                user: '=',
                tabName: '=',
                model: '=',
                exportSetter: '=',
                organization: '=',
                experiments: '=',
            },
            replace: true,
            template: `
                <article class="metrics-funnel-node">
                    <div class="grid-actions-panel">
                        <actions-panel-simple-item item="drilldownButtonModel"></actions-panel-simple-item>
                        <actions-panel-simple-item item="clearFiltersButtonModel"></actions-panel-simple-item>
                    </div>
                    <div class="ag-42 grid grid-new ag-theme-alpine"
                        ng-if="grid.options"
                        ag-grid="grid.options"
                    ></div>
                </article>
            `,
            link: function MetricsFunnelNodeViewDirectiveLink(scope) {
                scope.grid = MetricsFunnelNodeGridViewModel(scope);

                scope.onRowSelectionChange = (
                    rows: Record<string, Record<string, (string | number)[]> | undefined>,
                ) => {
                    view.enableDrilldownButton = !_.isEmpty(rows);
                };

                const view = {
                    enableDrilldownButton: false,
                    enableClearFiltersButton: false,
                };

                scope.drilldownModel = {
                    enabled: false,
                    active: false,
                    selectedValuesByProperty: {},
                };

                scope.drilldownButtonModel = new ActionsPanelSimpleItem({
                    label: 'Drilldown',
                    svg: FUNNEL_SVG,
                    isDisabled: () => !view.enableDrilldownButton,
                    disabledTooltip: 'No rows selected',
                    onClick: () => selectMultiplePropertiesDrilldown(),
                });

                scope.clearFiltersButtonModel = new ActionsPanelSimpleItem({
                    label: 'Clear Grid Filters',
                    icon: { type: 'icon-trash' },
                    isDisabled: () => !view.enableClearFiltersButton,
                    onClick: () => clearFilters(),
                });

                const selectMultiplePropertiesDrilldown = () => {
                    if (_.isEmpty(scope.drilldownModel.selectedValuesByProperty)) return;
                    const rowDrilldownValues = _.compact(Object.values(scope.drilldownModel.selectedValuesByProperty));
                    if (_.isEmpty(rowDrilldownValues)) return;
                    const values = rowDrilldownValues.reduce<Record<string, Set<string | number>>>((acc, rowValues) => {
                        for (const [key, value] of Object.entries(rowValues)) {
                            acc[key] ??= new Set();
                            value.forEach(v => acc[key]?.add(v));
                        }
                        return acc;
                    }, {});
                    const drilldown = Object.entries(values).reduce<Record<string, (string | number)[]>>(
                        (acc, [key, value]) => {
                            acc[key] = Array.from(value);
                            return acc;
                        },
                        {},
                    );

                    scope.model.drilldown(drilldown);
                    scope.drilldownModel.selectedValuesByProperty = {};
                    view.enableDrilldownButton = false;
                };

                const isProperty = (field: string) => /^property\d+/.test(field);
                const isItemMetric = (field: string) => field.startsWith('item_');
                const onError = (error: Error) => {
                    view.enableClearFiltersButton = !_.isEmpty(scope.model.node.getFiltering());
                    Analytics.logError(new Error('[metrics funnel] [refresh] error', { cause: error }));
                    scope.grid.setError(true);
                    throw error;
                };
                let watchFiltering: () => void = () => {};
                let watchColumnProperties: () => void = () => {};
                const refresh = () => {
                    watchFiltering();
                    watchColumnProperties();
                    scope.grid.setError(false);
                    scope.metricsGridDataModel = new MetricsGridDataModel({
                        funnel: scope.model,
                        organization: scope.organization,
                        actions: { onError },
                    });
                    scope.grid.options.api?.showLoadingOverlay();

                    watchFiltering = scope.$watch('model.node.filtering', () => {
                        if (!scope.metricsGridDataModel?.data) return;
                        scope.grid.setError(false);

                        const numberOfDisplayedRows = scope.grid.options.api?.getRenderedNodes().length ?? 0;
                        scope.metricsGridDataModel.updateTotal(numberOfDisplayedRows);
                    });
                    watchColumnProperties = scope.$watch('model.node.columnProperties', () => {
                        if (!scope.metricsGridDataModel?.data) return;
                        refresh();
                    });
                };

                const updateGridData = () => {
                    if (!scope.metricsGridDataModel) return;
                    scope.grid.updateData({
                        node: scope.model.node,
                        data: scope.metricsGridDataModel.data?.getRowsAndTotal() ?? null,
                        widths: scope.model.views.columns,
                        filters: convertToAGGridFiltering(
                            scope.model.node.getFiltering(),
                            scope.model.metrics.availableByField,
                        ),
                    });
                    view.enableClearFiltersButton = !_.isEmpty(scope.model.node.getFiltering());
                };

                scope.$watch('metricsGridDataModel.data', () => updateGridData());
                scope.$watch('metricsGridDataModel.funnel.node', () => {
                    if (scope.metricsGridDataModel && !scope.metricsGridDataModel.data) updateGridData();
                    view.enableDrilldownButton = false;
                });

                scope.$watch('metricsGridDataModel.total.data', () => {
                    if (!scope.metricsGridDataModel) return;
                    // console.log(
                    //     'total.data update',
                    //     scope.metricsGridDataModel.total?.getTotal() ?? getEmptyTotalRow(),
                    // );

                    const totalRows = (() => {
                        const hasMultipleProperties = scope.model.node.property.length > 1;
                        const hasColumnsAsProperties = scope.model.node.columnProperties.length > 0;

                        const total = scope.metricsGridDataModel.total?.getTotal();
                        if (!total) return getEmptyTotalRow();

                        if (hasColumnsAsProperties) return total;
                        if (hasMultipleProperties) return total.slice(0, 1);

                        return total;
                    })();

                    scope.grid.updateTotalRowData(totalRows, scope.model.node);
                });

                const clearFilters = () => {
                    if (!view.enableClearFiltersButton) return;
                    scope.grid.options.api?.setFilterModel(null);
                    view.enableClearFiltersButton = false;
                };

                scope.onColumnResize = (columnsResized: Record<string, number>) => {
                    scope.model.updateGridConfigColumnWidth(columnsResized);
                };
                scope.onRowSortChange = (sortedColumns: IMetricsGridSort[]) => {
                    scope.model.updateSort(sortedColumns);
                };

                scope.onFilterChanged = _.debounce((columnFilters: IAGGridFiltering) => {
                    const metricsByField = _.keyBy(scope.model.metrics.selected, 'field');
                    // when the cellFilter is percentage, the `numberParser` picks the filter value and does this transformation FilterExpressionParser.percent(Number(text))
                    // so we need to multiply the filter value by 100 to get the correct value to save, otherwise it would change the value the user inputs
                    for (const [key, columnFilter] of Object.entries(columnFilters)) {
                        if (isProperty(key)) continue;
                        const metric = metricsByField[key];
                        if (!metric) throw new Error(`Metric ${key} not found`);
                        if (metric.cellFilter?.startsWith('percent')) {
                            if ('filter' in columnFilter && typeof columnFilter.filter === 'number') {
                                columnFilter.filter = getPercentFilterValue(columnFilter.filter);
                            }
                            if ('condition1' in columnFilter && typeof columnFilter.condition1.filter === 'number') {
                                columnFilter.condition1.filter = getPercentFilterValue(columnFilter.condition1.filter);
                            }
                            if ('condition2' in columnFilter && typeof columnFilter.condition2.filter === 'number') {
                                columnFilter.condition2.filter = getPercentFilterValue(columnFilter.condition2.filter);
                            }
                        }
                    }

                    if (
                        _.isEqual(
                            convertToAGGridFiltering(
                                scope.model.node.getFiltering(),
                                scope.model.metrics.availableByField,
                            ),
                            columnFilters,
                        )
                    )
                        return;
                    view.enableClearFiltersButton = !_.isEmpty(columnFilters);
                    scope.model.updateGridColumnFilters(columnFilters);
                }, 100);

                scope.exportSetter(() => {
                    const query = _.cloneDeep($rootScope.query ?? {});
                    return $q.when(MetricsKPIsService.fetch(query)).then(metrics => {
                        const metricsByField = Object.fromEntries(metrics.map(x => [x.field, x]));

                        const associatedProperties = MetricsFunnelNodeService.getAssociatedProperties(scope.model.node);
                        const itemMetricProperties = metrics
                            .filter(x => isItemMetric(x.field))
                            .map(x => x.field.replace(/^item_/, 'items.'));

                        const metricColumnDefs = scope.grid.getColumnDefs(scope.model.node, null).flatMap(x => {
                            const field = x.field;
                            if (typeof field !== 'string') return [];
                            if (isProperty(field) || field === 'item_image__left') return [];
                            x = deepStripAngularProperties(x);
                            // FIXME: why not use the x._cellClass?
                            const metricDef = metricsByField[field];
                            if (metricDef?._cellClass) x.cellClass = metricDef._cellClass;
                            delete x._cellClass;
                            delete x.cellRenderer;
                            delete x.drilldown;
                            delete x.filter;
                            delete x.columnViewName;
                            delete x.category;
                            return [x];
                        }, []);

                        query.options = {
                            ...(query.options ?? {}),
                            associatedProperties: [...associatedProperties, ...itemMetricProperties],
                            metrics: _.compact(metricColumnDefs.map(columnDef => columnDef.field)),
                        };

                        query.export = {
                            properties: deepStripAngularProperties(scope.model.properties),
                            columnDefs: metricColumnDefs,
                        };

                        return MetricsFunnelNodeService.runExport({
                            query,
                            node: scope.model.node,
                            tabName: scope.tabName,
                            metricsByField,
                        });
                    });
                });

                scope.$watch('grid.columnSortOrder', (columnSortOrder: string[]) => {
                    scope.model.updateColumnSortOrder(columnSortOrder);
                });

                scope.$watch(
                    'model.metrics.selected',
                    (selectedMetrics: IColumnDef[]) => {
                        const gridMetrics = scope.grid.columnSortOrder;
                        const modelMetrics = selectedMetrics.map(x => x.field);
                        if (_.isEqual(gridMetrics, modelMetrics)) return;
                        scope.grid.updateColumns({
                            node: scope.model.node,
                            columns: scope.model.metrics.selected,
                            widths: scope.model.views.columns ?? {},
                            rows: scope.grid.getRows(),
                        });
                    },
                    true,
                );

                scope.$watch('model.node', () => refresh());
                scope.$on(
                    '$destroy',
                    $rootScope.$on('query.refresh', () => refresh()),
                );
            },
        };
    },
];

export type MetricCellRenderer = (data: unknown) => string;
export interface IMetricsGridCellRenderers {
    image: MetricCellRenderer;
}

export interface IMetricsFunnelNodeGridViewModelOptions {
    suppressSorting?: boolean | undefined;
    suppressFiltering?: boolean | undefined;
}

export type IMetricsFunnelNodeGridViewModelFactory = AngularInjected<typeof MetricsFunnelNodeGridViewModelFactory>;
export type IMetricsFunnelNodeGridViewModel = ReturnType<IMetricsFunnelNodeGridViewModelFactory>;

export const MetricsFunnelNodeGridViewModelFactory = () => [
    'MetricsGridCellRenderers',
    'MetricsGridDefaultCellRenderer',
    function MetricsFunnelNodeGridViewModelInstance(
        MetricsGridCellRenderers: IMetricsGridCellRenderers,
        MetricsGridDefaultCellRenderer: MetricCellRenderer,
    ) {
        // make sure this matches the CSS
        const metricHeaderGroupFont = new FontWidthCalculator({
            font: '700 10px "Open Sans"',
            textTransform: 'uppercase',
            letterSpacing: 1,
        });

        const metricHeaderNameFont = new FontWidthCalculator({
            font: '400 10px "Open Sans"',
            textTransform: 'uppercase',
            letterSpacing: 1,
        });

        return (scope: IMetricsFunnelNodeViewDirectiveScope, options: IMetricsFunnelNodeGridViewModelOptions = {}) => {
            let dataColumns: IColumnDef[] = [];
            const rowsOverlay = GridOverlayMessageRendererFactory();

            const gridOptions: AgGrid.GridOptions = {
                applyColumnDefOrder: true,
                angularCompileFilters: true,
                headerHeight: GRID_HEADER_ROW_HEIGHT,
                suppressDragLeaveHidesColumns: true,
                sortingOrder: options.suppressSorting ? [null] : ['desc', 'asc', null],
                noRowsOverlayComponent: rowsOverlay.component,
                rowSelection: 'multiple',
                groupSelectsChildren: true,
                suppressRowClickSelection: true,
                suppressAggFuncInHeader: true,
                suppressRowHoverHighlight: false,

                onRowSelected: params => {
                    const drilldown = scope.model.node.property.reduce<Record<string, (string | number)[]>>(
                        (acc, property, index) => {
                            if ('data' in params && isObject(params.data)) {
                                const value = params.data[`property${index}`];
                                if (value === undefined || (typeof value !== 'string' && typeof value !== 'number'))
                                    return acc;
                                return { ...acc, [property.id]: [value] };
                            }
                            return acc;
                        },
                        {},
                    );

                    if (_.isEmpty(drilldown)) return;
                    if (gridOptions.api) {
                        const { rowIndex } = params;
                        if (_.isNil(rowIndex)) return;
                        if (scope.drilldownModel.selectedValuesByProperty[rowIndex]) {
                            scope.drilldownModel.selectedValuesByProperty = _.omit(
                                scope.drilldownModel.selectedValuesByProperty,
                                rowIndex,
                            );
                        } else {
                            scope.drilldownModel.selectedValuesByProperty[rowIndex] = drilldown;
                        }
                    }

                    scope.onRowSelectionChange(scope.drilldownModel.selectedValuesByProperty);
                },

                onSortChanged: event => {
                    console.log('[metrics][grid] onSortChanged:', event);
                    const columns = event.columnApi.getColumnState();
                    let columnsWithSort: { sortIndex?: null | undefined | number; sort: IMetricsGridSort }[] =
                        columns.flatMap(col => {
                            const { colId: field, sort, sortIndex } = col;
                            if (!field) return [];
                            const order = sort?.toLocaleLowerCase() === 'asc' ? 1 : sort === 'desc' ? -1 : undefined;
                            if (order === undefined) return [];
                            return [{ sortIndex: sortIndex, sort: { field, order } }];
                        });
                    columnsWithSort = _.sortBy(columnsWithSort, ['sortIndex']);
                    scope.onRowSortChange(columnsWithSort.map(x => x.sort));
                    scope.$applyAsync();
                },

                onColumnResized: _.debounce((event: AgGrid.ColumnResizedEvent) => {
                    if (!event.column && !event.columns) return;
                    const columns = event.column ? [event.column] : event.columns ?? [];
                    const updated = Object.fromEntries(columns.map(c => [c.getColId(), c.getActualWidth()]));
                    console.log('[metrics][grid] onColumnResized:', updated);
                    scope.onColumnResize(updated);
                    scope.$applyAsync();
                }, 200),

                onDragStopped: () => {
                    updateColumnOrder();
                },

                onFilterChanged: event => {
                    if (event.type !== 'filterChanged') return;
                    const metricsFiltersModel = _.cloneDeep(event.api.getFilterModel());
                    scope.onFilterChanged(metricsFiltersModel);
                },
            };

            const setError = (error: boolean) => {
                if (error) {
                    rowsOverlay.setError(true);
                    gridOptions.api?.hideOverlay();
                    gridOptions.api?.showNoRowsOverlay();
                } else {
                    rowsOverlay.setError(false);
                    gridOptions.api?.hideOverlay();
                }
            };

            const updateColumnOrder = () => {
                const columnDefs = gridOptions.api?.getColumnDefs() ?? [];
                const columns: AgGrid.ColDef[] = columnDefs.flatMap(x => ('children' in x ? x.children : []));
                const prev = modelFields.columnSortOrder;
                const curr = columns.flatMap(x =>
                    (_.isNil(x.pinned) || x.pinned === false) && typeof x.field === 'string' ? [x.field] : [],
                );
                if (_.isEqual(curr, prev)) return;
                modelFields.columnSortOrder = curr;
                // Preserve Order if user changes order of columns during rows update
                if (columns.length === 0) return;
                const fields = columns.map(x => x.field);
                dataColumns.sort((a, b) => fields.indexOf(a.field) - fields.indexOf(b.field));
            };

            const shouldShowImageColumn = (node: MetricsFunnelNode): boolean => {
                const property = node.property;
                return property.every(x => x.id.startsWith('items.') && x.id !== 'items.season');
            };

            const getImageColumnDef = (): null | Omit<IColumnDef, 'headerGroup'> => {
                return {
                    headerName: '',
                    field: 'item_image__left', // This is used as a column ID
                    width: GRID_DATA_ROW_HEIGHT__WITH_IMAGE,
                    cellClass: 'item-image-render',
                    lockPinned: true,
                    lockPosition: true,
                    sortable: false,
                    pinned: true,
                    autoHeight: true,
                    flex: 0,
                    cellRenderer: (params: { data: Record<string, string | number> }) => {
                        return isTotalRow(params.data) ? '' : MetricsGridCellRenderers.image(params.data);
                    },
                };
            };

            const getRowCalendarSortTimestamp = (node: AgGrid.RowNode): string | null => {
                // Using assertions here for performance reasons...
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
                const timestamp: unknown = node.data.calendar__timerange_start;
                return typeof timestamp === 'string' ? timestamp : null;
            };

            const CalendarPropertyComparator = (
                sort: undefined | null | { nulls?: 'first' | 'last' | undefined | null },
            ): NonNullable<IColumnDef['comparator']> => {
                const nullRank = sort?.nulls === 'first' ? 1 : sort?.nulls === 'last' ? -1 : 0;
                return (_, __, nodeA, nodeB): number => {
                    const timerangeA = getRowCalendarSortTimestamp(nodeA);
                    if (timerangeA === null) return nullRank;
                    const timerangeB = getRowCalendarSortTimestamp(nodeB);
                    if (timerangeB === null) return nullRank;
                    return timerangeA === timerangeB ? 0 : timerangeA < timerangeB ? -1 : 1;
                };
            };

            const calendarPropertyComparator = CalendarPropertyComparator(undefined);
            const getGroupByColumnDef = (node: MetricsFunnelNode): IColumnDef[] => {
                // Fetch the properties from the node uses:
                // query.options.properties = [ ...node.columnProperties, ...node.property ]
                // So, to get the metrics values, it starts in the index after the columns properties
                // So, node.columnProperties.length is the first index of the metrics values
                const propertiesAsColumnsLength = node.columnProperties?.length ?? 0;
                return _.compact(
                    node.property
                        .map((_, i) => `property${i + propertiesAsColumnsLength}`)
                        .map((propertyN, index) => {
                            const property = node.property[index];
                            if (!property) return null;
                            return {
                                field: propertyN,
                                cellClass: (params: { rowIndex: number; data?: Record<string, string | number> }) => {
                                    const propertyId = scope.model.node.property[index]?.id;
                                    const isSelected = (() => {
                                        if (!propertyId || !('data' in params) || !(propertyN in params.data))
                                            return false;
                                        const value = params.data[propertyN];
                                        if (_.isNil(value)) return false;
                                        return Boolean(scope.drilldownModel.selectedValuesByProperty[params.rowIndex]);
                                    })();

                                    const rowValue = isTotalRow(params.data) ? 'total' : '';
                                    const selectedClass = isSelected ? 'selected' : '';
                                    return `pinned-property ${rowValue} ${selectedClass}`;
                                },
                                filter: options.suppressFiltering === true ? false : 'agTextColumnFilter',
                                sortable: options.suppressSorting === true ? false : true,
                                headerName: property.label,
                                lockPinned: true,
                                lockPosition: true,
                                // checkboxSelection: true,
                                cellRenderer: (params: { data: Record<string, string | number> }) => {
                                    if (isTotalRow(params.data)) {
                                        if (isLoadingTotalRow(params.data)) return 'Loading Total ...';
                                        return index === 0 ? 'Total' : '';
                                    }
                                    return purifyText(params.data[propertyN]);
                                },
                                // it does not drilldown in Ads Page
                                onCellClicked: params => {
                                    if (isTotalRow(params.data)) return;

                                    const propertiesAsColumnsLength = scope.model.node.columnProperties.length;
                                    const drilldown = scope.model.node.property.reduce<
                                        Record<string, (string | number)[]>
                                    >((acc, property, index) => {
                                        if ('data' in params && isObject(params.data)) {
                                            const value = params.data[`property${index + propertiesAsColumnsLength}`];
                                            if (
                                                value === undefined ||
                                                (typeof value !== 'string' && typeof value !== 'number')
                                            )
                                                return acc;
                                            return { ...acc, [property.id]: [value] };
                                        }
                                        return acc;
                                    }, {});

                                    if (_.isEmpty(drilldown)) return;
                                    scope.$applyAsync(() => {
                                        scope.model.drilldown(drilldown);
                                    });
                                },
                            };
                        }),
                );
            };

            const addCheckboxColumnDef = (columns: IColumnDef[]): IColumnDef[] => {
                const checkboxColumn: IColumnDef = {
                    field: 'checkbox-column',
                    cellClass: 'checkbox-cell',
                    filter: false,
                    sortable: false,
                    resizable: false,
                    headerName: '',
                    width: 80,
                    lockPinned: true,
                    lockPosition: true,
                    checkboxSelection: true,
                    headerCheckboxSelection: false,
                };

                columns.unshift(checkboxColumn);
                return columns;
            };

            const getDimensionColumnDefs = (node: MetricsFunnelNode): IColumnDef[] => {
                const groupByColumn = (() => {
                    const columns = getGroupByColumnDef(node);
                    if (scope.experiments.checkboxDrilldown) return addCheckboxColumnDef(columns);
                    return columns;
                })();
                const width = 200;
                const columns: IColumnDef[] = [];

                const result = groupByColumn.map(g => ({
                    ...g,
                    width: g.width ?? width,
                }));

                return [...result, ...columns].map(column => {
                    const propertyId = (() => {
                        const isProperty = column.field.startsWith('property');
                        if (isProperty) {
                            const indexOfProperty = Number(column.field.split('property')[1]);
                            if (_.isNumber(indexOfProperty)) {
                                const property = node.property[indexOfProperty];
                                if (isObject(property)) return property.id;
                            }
                        }
                        return column.field;
                    })();
                    const comparator = propertyId.startsWith('calendar.')
                        ? { comparator: calendarPropertyComparator }
                        : {};
                    return {
                        wrapText: true,
                        flex: 1,
                        ...comparator,
                        ...column,
                    };
                });
            };

            const mergeColumnDefs = (node: MetricsFunnelNode, metrics: IColumnDef[]): IColumnDef[] => {
                const imageColumn = shouldShowImageColumn(node) ? getImageColumnDef() : null;
                const rows = getDimensionColumnDefs(node);
                const pinnedColumns: IColumnDef[] = [...rows, ...(imageColumn ? [imageColumn] : [])].map(column => {
                    return {
                        pinned: 'left',
                        suppressMovable: true,
                        lockPinned: true,
                        lockPosition: true,
                        ...column,
                    };
                });
                metrics = metrics.map(metric => ({ ...metric }));
                return pinnedColumns.concat(metrics);
            };

            const getColumnDefs = (node: MetricsFunnelNode, columnsDefs: IColumnDef[] | null): IColumnDef[] => {
                columnsDefs ??= _.cloneDeep(dataColumns);
                const columns = mergeColumnDefs(node, columnsDefs);

                columns.forEach((columnDef: IColumnDef) => {
                    // NOTE: messing with this stuff can remove the ability to filter on the property column, so refactor with caution...
                    if (columnDef.field) columnDef.headerName ??= titleize(columnDef.field);
                    columnDef.cellRenderer ??= MetricsGridDefaultCellRenderer;
                    columnDef.columnGroupShow = 'open';
                    columnDef.resizable ??= true;
                    columnDef.sortable ??= options.suppressSorting ? false : true;
                    columnDef.filter ??= (() => {
                        if (options.suppressFiltering) return false;
                        if (!columnDef.cellFilter) return false;
                        const cellFilterValue = columnDef.cellFilter.split(':')[0];
                        return cellFilterValue && ['number', 'percent', 'money'].includes(cellFilterValue)
                            ? 'agNumberColumnFilter'
                            : false;
                    })();

                    if (columnDef.filter === 'agNumberColumnFilter') {
                        const filterType = (() => {
                            const cellFilterValue = columnDef.cellFilter?.split(':')[0];
                            if (cellFilterValue) {
                                return ['number', 'percent', 'money'].find(filterType =>
                                    filterType.includes(cellFilterValue),
                                );
                            }
                        })();

                        columnDef.filterParams = {
                            allowedCharPattern: '\\d\\-\\%\\.',
                            filterOptions: [
                                'equals',
                                'notEqual',
                                'lessThan',
                                'lessThanOrEqual',
                                'greaterThan',
                                'greaterThanOrEqual',
                                // remove until we support $in operator and test it
                                //'inRange',
                            ],
                            numberParser: (text: string | null | number) => {
                                if (text !== null && filterType) {
                                    switch (filterType) {
                                        case 'number':
                                            text = FilterExpressionParser.number(Number(text))?.value ?? text;
                                            break;
                                        case 'money':
                                            text = FilterExpressionParser.money(text)?.value ?? text;
                                            break;
                                        case 'percent':
                                            text = FilterExpressionParser.percent(Number(text))?.value ?? text;
                                            break;
                                    }
                                }

                                return text;
                            },
                        };
                    }
                });

                return columns;
            };

            // Helper to append one or more classes to an existing cell class, that works iteratively.
            const CellClassExtender = () => {
                const __originalCellClass__ = Symbol();

                const isWrappedCellClassFn = (
                    x: unknown,
                ): x is CallableFunction & { [__originalCellClass__]: AgGrid.ColDef['cellClass'] } => {
                    return _.isFunction(x) && __originalCellClass__ in x;
                };

                const normalizeCellClass = (cellClass: AgGrid.ColDef['cellClass']) => {
                    const getter = _.isFunction(cellClass) ? cellClass : () => cellClass;
                    return (params: AgGrid.CellClassParams) => {
                        const result = getter(params);
                        return Array.isArray(result)
                            ? result
                            : typeof result === 'string' && result.length > 0
                            ? [result]
                            : [];
                    };
                };

                return (cellClass: AgGrid.ColDef['cellClass'], extendedCellClass: AgGrid.ColDef['cellClass']) => {
                    const originalCellClass = isWrappedCellClassFn(cellClass)
                        ? cellClass[__originalCellClass__]
                        : cellClass;
                    // if there's nothing to extend, then we exit early as an optimization...
                    if (extendedCellClass === undefined) return originalCellClass;
                    if (Array.isArray(extendedCellClass) && extendedCellClass.length === 0) return originalCellClass;
                    const originalCellClassFn = normalizeCellClass(originalCellClass);
                    const extendedCellClassFn = normalizeCellClass(extendedCellClass);
                    const wrappedCellClassFn = (params: AgGrid.CellClassParams) => {
                        const original = originalCellClassFn(params);
                        const extended = extendedCellClassFn(params);
                        return [...original, ...extended];
                    };
                    Object.defineProperty(wrappedCellClassFn, __originalCellClass__, { value: originalCellClass });
                    return wrappedCellClassFn;
                };
            };

            const updateCellValueGroupEndCellClass = (() => {
                const extendCellClass = CellClassExtender();
                return (columns: IColumnDef[]) => {
                    return columns.map((column, index, array) => {
                        const nextColumn = array[index + 1];
                        const prevColumn = array[index - 1];
                        const nextHeaderGroup = nextColumn?.headerGroup;
                        const prevHeaderGroup = prevColumn?.headerGroup;
                        const currHeaderGroup = column.headerGroup;
                        const isEnd = nextHeaderGroup !== currHeaderGroup;
                        const isStart = prevHeaderGroup !== currHeaderGroup;
                        const cellClass = extendCellClass(column.cellClass, [
                            ...(isStart ? ['column-group-start'] : []),
                            ...(isEnd ? ['column-group-end'] : []),
                        ]);
                        return { ...column, cellClass };
                    });
                };
            })();

            const updatePinnedNameColumn = (names: string[], numberOfPropertiesAsColumns: number): void => {
                const columns = gridOptions.api?.getColumnDefs() ?? [];
                const pinnedGroup = columns.find(x => 'groupId' in x && x.groupId === ' ');
                if (!pinnedGroup || !('children' in pinnedGroup)) return;
                const children: AgGrid.ColDef[] = pinnedGroup.children.filter(x => 'field' in x);
                const pinnedColumns: AgGrid.ColDef[] = [];
                let indexf = 0;
                children.forEach(child => {
                    if ('field' in child && child.field.startsWith('property')) {
                        const propertyIndex = child.field.split('property')[1];
                        if (propertyIndex === undefined) return;
                        const name = names[Number(propertyIndex) - numberOfPropertiesAsColumns];
                        if (name) child.headerName = name;
                        indexf++;
                    }

                    if (indexf <= names.length) {
                        pinnedColumns.push(child);
                    }
                });
                pinnedGroup.children = pinnedColumns;
                gridOptions.api?.setColumnDefs(columns);
            };

            let dataRows: IMetricsPageGridDataRow[] = [];
            const getRows = () => _.cloneDeep(dataRows);

            const updateColumns = (params: {
                node: MetricsFunnelNode;
                columns: IColumnDef[];
                rows?: IMetricsPageGridDataRow[];
                widths?: IMetricsGridConfigViewsColumns;
            }) => {
                const { node, columns, rows = [], widths = {} } = params;
                dataRows = _.cloneDeep(rows);
                dataColumns = _.cloneDeep(columns);
                const hasImage = shouldShowImageColumn(node);
                gridOptions.api?.setGetRowHeight((params: { data: Record<string, unknown> }) => {
                    return isTotalRow(params.data)
                        ? GRID_HEADER_ROW_HEIGHT
                        : hasImage
                        ? GRID_DATA_ROW_HEIGHT__WITH_IMAGE
                        : GRID_DATA_ROW_HEIGHT;
                });
                const columnDefs = updateCellValueGroupEndCellClass(getColumnDefs(node, columns));
                const gridColumnDefs = gridOptions.api?.getColumnDefs() ?? [];
                const oldColumnDefs = _.cloneDeep(gridColumnDefs);
                const gridColumnDefsByGroupId = _.keyBy(
                    _.cloneDeep(gridColumnDefs).filter(x => 'groupId' in x),
                    'groupId',
                );

                const columnDefGroups = columnDefs.reduce<Record<string, AgGrid.ColGroupDef>>((acc, column) => {
                    column.headerGroup = column.headerGroup ?? ' ';
                    const headerName = column.headerGroup;

                    const columnDefinition: AgGrid.ColGroupDef = (() => {
                        const columnDef = acc[headerName];
                        if (columnDef) return columnDef;
                        const gridColumnDef = gridColumnDefsByGroupId[headerName];
                        if (gridColumnDef) return { ...gridColumnDef, children: [] };
                        return {
                            groupId: headerName,
                            headerName: headerName,
                            marryChildren: true,
                            headerClass: ['column-group-start', 'column-group-end'],
                            children: [],
                        };
                    })();

                    const existingGridChild = (() => {
                        if (!gridColumnDefsByGroupId[headerName]) return;
                        const columnDef = gridColumnDefsByGroupId[headerName];
                        return columnDef && 'children' in columnDef
                            ? columnDef.children.find(child => 'field' in child && child.field === column.field)
                            : undefined;
                    })();

                    if (existingGridChild) {
                        columnDefinition.children.push(existingGridChild);
                    } else {
                        const child = _.omit(column, 'headerGroup');
                        const cellClass = child.cellClass ?? [];
                        const headerClass = child.cellClass ?? [];
                        columnDefinition.children.push({ ...child, cellClass, headerClass, groupId: headerName });
                    }

                    const sort = node.getSort();
                    if (sort && 'children' in columnDefinition) {
                        columnDefinition.children.forEach(c => {
                            if ('field' in c) {
                                const field = c.field;
                                const columnSortIndex = sort.findIndex(s => s.field === field);
                                if (columnSortIndex === -1) {
                                    c.sort = null;
                                } else {
                                    const columnSort = sort[columnSortIndex] ?? { order: 1 };
                                    c.sort = columnSort.order === 1 ? 'asc' : 'desc';
                                    c.sortIndex = columnSortIndex;
                                }
                            }
                        });
                    }

                    acc[headerName] = columnDefinition;
                    return acc;
                }, {});

                const columnDefsResult = Object.values(columnDefGroups);
                columnDefsResult.forEach(c => fixColumnWidths(c, gridColumnDefs, widths));
                const unpinnedColumnDefs = _.cloneDeep(columnDefsResult.slice(1));
                const propertiesAsColumnsLength = node.columnProperties?.length ?? 0;

                if (propertiesAsColumnsLength > 0 && rows.length > 0) {
                    const properties = Array.from({ length: propertiesAsColumnsLength }, (_, i) => `property${i}`);
                    const propertiesAsColumns = node.columnProperties.map(p => p.id);

                    const group = (items: IMetricsPageGridDataRow[], propertyIndex: number): AgGrid.ColGroupDef[] => {
                        const property = properties[propertyIndex];
                        const groups: { [key: string]: AgGrid.ColGroupDef[] } = {};

                        // Group items by the current property
                        for (const item of items) {
                            if (_.isNil(property)) continue;
                            const key = item[property];
                            if (typeof key !== 'string') continue;
                            if (!groups[key]) {
                                groups[key] = [];
                            }
                            groups[key].push(item);
                        }

                        const buildProperty = (
                            key: string,
                            propertiesAsColumns: string[],
                            children: AgGrid.ColGroupDef[],
                        ) => {
                            return {
                                groupId: key,
                                headerName: key,
                                marryChildren: true,
                                propertyAsColumn: propertiesAsColumns,
                                children: _.cloneDeep(children),
                            };
                        };

                        return Object.keys(groups).map(key => {
                            // Create TreeNode for each group
                            return buildProperty(
                                key,
                                propertiesAsColumns,
                                propertyIndex === properties.length - 1
                                    ? unpinnedColumnDefs
                                    : group(groups[key], propertyIndex + 1),
                            );
                        });
                    };

                    // Start grouping from the first property
                    const result = group(rows, 0);
                    gridOptions.api?.setColumnDefs([columnDefsResult[0]].concat(result));
                    updateColumnOrder();

                    return;
                }

                if (propertiesAsColumnsLength > 0 && rows.length === 0) {
                    const oldColDefs = oldColumnDefs.slice(1);
                    if (oldColDefs.length !== 0) {
                        const updateNames = (c: AgGrid.ColDef | undefined) => {
                            if (c && 'propertyAsColumn' in c && Boolean(c.propertyAsColumn)) {
                                c.headerName = ' loading... ';

                                if ('children' in c && Array.isArray(c)) c.children.forEach(child => updateName(child));
                            }
                            return;
                        };

                        const newPropertiesAsColumns = node.columnProperties.map(p => p.id);
                        const oldPropertiesAsColumns = oldColDefs[0]?.propertyAsColumn ?? null;

                        if (!_.isEqual(oldPropertiesAsColumns, newPropertiesAsColumns))
                            oldColDefs.forEach(col => updateNames(col));

                        gridOptions.api?.setColumnDefs(_.cloneDeep([columnDefsResult[0]].concat(oldColDefs)));

                        console.log('vim aqui sera');
                        updateColumnOrder();
                        return;
                    }
                }

                gridOptions.api?.setColumnDefs(_.cloneDeep(columnDefsResult));
                updateColumnOrder();
            };

            const fixColumnWidths = (
                columnDef: AgGrid.ColGroupDef,
                gridColumnDefs: (AgGrid.ColDef | AgGrid.ColGroupDef)[],
                widths: IMetricsGridConfigViewsColumns,
            ) => {
                if (columnDef.children.length === 0 || isSameColumnDef(columnDef, gridColumnDefs)) return;

                let childTotalWidth = 0;

                columnDef.children.forEach((child: AgGrid.ColDef) => {
                    const text = (child.headerName ?? '').trim();
                    child.width = child.width ?? Math.ceil(metricHeaderNameFont.getPixelWidth(text)) + 16 + 2 * 22;
                    child.width = child.field === 'checkbox-column' ? child.width : Math.max(120, child.width);
                    childTotalWidth += child.width;
                });

                const headerGroupText = (columnDef.headerName ?? '').trim();
                const headerGroupTextWidth = Math.ceil(metricHeaderGroupFont.getPixelWidth(headerGroupText)) + 36;
                if (headerGroupTextWidth > childTotalWidth) {
                    const diff = headerGroupTextWidth - childTotalWidth;
                    const toAdd = diff / columnDef.children.length;
                    columnDef.children.forEach((c: AgGrid.ColDef) => (c.width = (c.width ?? 0) + toAdd));
                }

                columnDef.children.forEach((c: AgGrid.ColDef) => {
                    const widthValue = !_.isNil(c.field) ? widths[c.field] : null;
                    if (typeof widthValue === 'number') c.width = widthValue;
                });
            };

            const isSameColumnDef = (
                columnGroupDef: AgGrid.ColGroupDef,
                gridColumnDefs: (AgGrid.ColDef | AgGrid.ColGroupDef)[] | undefined,
            ) => {
                const colId = columnGroupDef.groupId;

                gridColumnDefs = gridColumnDefs ?? [];
                const gridColumn = gridColumnDefs.find(col => 'groupId' in col && col.groupId === colId);

                if (!gridColumn) {
                    return false;
                }

                if ('children' in gridColumn) {
                    const columnGroupDefChildren = columnGroupDef.children;
                    const gridColumnChildren = gridColumn.children;

                    if (columnGroupDefChildren.length !== gridColumnChildren.length) {
                        return false;
                    }

                    return columnGroupDefChildren.every(child => {
                        return (
                            gridColumnChildren.findIndex(
                                gridChild =>
                                    'field' in gridChild && 'field' in child && gridChild.field === child.field,
                            ) > -1
                        );
                    });
                }

                return false;
            };

            const updateTotalRowData = (data: IMetricsPageGridDataRow[] | null, node: MetricsFunnelNode) => {
                const totalRow = gridOptions.api?.getPinnedTopRow(0);
                if (!totalRow) return;

                const rows = (() => {
                    if (node.columnProperties.length > 0) {
                        const mappedTotalRowsForColumnProperties = mapTotalRowsAsColumns(
                            data ?? [],
                            node.property.length,
                            node.columnProperties.length,
                        );
                        return mappedTotalRowsForColumnProperties;
                    }
                    return data;
                })();

                if (_.isEqual(rows?.[0], totalRow.data)) return;
                gridOptions.api?.setPinnedTopRowData(rows ?? []);
            };

            const updateData = (params: {
                node: MetricsFunnelNode;
                data: IMetricsFunnelRowData | null;
                widths: IMetricsGridConfigViewsColumns;
                filters?: IAGGridFiltering;
            }) => {
                const { node, data, widths = {} } = params;
                const { rows, total } = { ...data };

                const filters = params.filters ?? convertToAGGridFiltering(node.getFiltering());
                if (_.isNil(rows)) {
                    gridOptions.api?.setPinnedTopRowData([]);
                    gridOptions.api?.setRowData([]);
                    gridOptions.api?.setFilterModel(filters);
                    gridOptions.api?.showLoadingOverlay();
                    updateColumns({
                        node,
                        columns: dataColumns,
                        widths,
                    });
                    setTimeout(
                        () =>
                            updatePinnedNameColumn(
                                node.property.map(p => p.label),
                                node.columnProperties.length,
                            ),
                        0,
                    );
                } else {
                    // TODO
                    // Review with Properties As Columns
                    updateColumns({ node, columns: dataColumns, rows, widths });

                    if (node.columnProperties.length > 0) {
                        const mappedRowsForColumnProperties = mapRowsAsColumns(rows, node.columnProperties.length);
                        const totalRow = (() => {
                            const mappedTotalRowsForColumnProperties = mapTotalRowsAsColumns(
                                total ?? [],
                                node.property.length,
                                node.columnProperties.length,
                            );
                            return mappedTotalRowsForColumnProperties;
                        })();

                        gridOptions.api?.setPinnedTopRowData(totalRow ?? []);
                        gridOptions.api?.setRowData(mappedRowsForColumnProperties);
                    } else {
                        const totalRows = (() => {
                            const hasMultipleProperties = scope.model.node.property.length > 1;
                            if (!total) return [];
                            if (hasMultipleProperties) return total.slice(0, 1);
                            return total;
                        })();

                        gridOptions.api?.setPinnedTopRowData(totalRows);
                        gridOptions.api?.setRowData(rows);
                    }

                    // TODO
                    // Review with Properties As Columns
                    gridOptions.api?.setFilterModel(filters);
                    gridOptions.api?.hideOverlay();
                    if (rows.length === 0) {
                        gridOptions.api?.showNoRowsOverlay();
                    }
                }
            };

            const resetColumnDefs = () => gridOptions.api?.setColumnDefs([]);

            const columnSortOrder: string[] = []; // NOTE: list of metric ids
            const modelFields = {
                options: gridOptions,
                updateData,
                resetColumnDefs,
                getRows,
                getColumnDefs,
                updateColumns,
                updateTotalRowData,
                columnSortOrder,
                setError,
            };

            return modelFields;
        };
    },
];

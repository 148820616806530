import _ from 'lodash';
import { titleize } from 'inflected';
import * as Auth from '../../lib/auth';
import type { IQuery } from '../../lib/types';
import type { IQueryMetrics, DashboardRootScope } from '../main-controller';
import { AdsPlatformConfig, AdsConfig, fetchAdsConfig } from './ads.service';
import { IMetricDefinitionExtended, processMetrics } from './ads.helper';
import { IAdsGrid, IAdsGridFactory } from './components/ads-grid.directive';
import { AdsChart } from './components/ads-chart.directive';
import { IPropertyDefinition } from '../../lib/config-hierarchy';
import AdsStorage, { IAdsStorage } from './ads.storage';
import { AngularInjected } from '../../lib/angular';
import { MetricsFunnelNode } from '../metrics/metrics-funnel';
import { IDashboardQuery } from '../main/dashboard-models';
import { IAdsStats, IAdsStatsFactory } from './components/ads-stats.directive';

export interface IAdViewOptions {
    availableMetrics: IMetricDefinitionExtended[];
    platform: string;
    config: AdsPlatformConfig;
    query: IQuery;
    properties: IPropertyDefinition[];
    metrics: IMetricDefinitionExtended[];
    chartMetrics: IMetricDefinitionExtended[];
}

const DEFAULT_COLOR = '#40e0d0';
const METRICS_COLORS = ['#40e0d0', '#7b68ee', '#ff6666', '#9FE18D', '#FFB665', '#FB85D0', '#CBACF6'];

// This is done so the Metric color is the same across different platforms
const getKpiByColor = (platforms: string[], config: AdsConfig) => {
    const kpiByColor = _.uniq(
        platforms.reduce((acc: string[], platform) => {
            return acc.concat(config.platforms[platform]?.kpis ?? []);
        }, []),
    ).reduce((acc: Record<string, string>, kpi, index) => {
        acc[kpi] = METRICS_COLORS[index % METRICS_COLORS.length] ?? DEFAULT_COLOR;
        return acc;
    }, {});

    return kpiByColor;
};

export const AdViewFactory = () => [
    'AdsStats',
    'AdsGrid',
    function AdViewModel(AdsStats: IAdsStatsFactory, AdsGrid: IAdsGridFactory) {
        return class AdView {
            stats: IAdsStats | null = null;
            title: string;
            platform: string;
            config: AdsPlatformConfig;
            grid: IAdsGrid;
            chart: AdsChart;
            availableMetrics: IMetricDefinitionExtended[];
            metrics: IMetricDefinitionExtended[];
            properties: IPropertyDefinition[];
            property: IPropertyDefinition | undefined;

            constructor(options: IAdViewOptions) {
                const { platform, config, query, availableMetrics, properties, metrics, chartMetrics } = options;
                this.platform = platform;
                this.availableMetrics = availableMetrics;
                this.metrics = metrics;
                this.properties = properties;
                this.title = titleize(platform);
                this.config = config;

                this.property = (() => {
                    if (!config.property) return properties[0];

                    const propertyId = `${config.property}.${config.propertyFilter}`;
                    return properties.find(p => p.id === propertyId) ?? properties[0];
                })();

                if (!this.property) {
                    throw new Error(`Property not found for platform ${platform}`);
                }

                this.stats = new AdsStats({
                    title: this.title,
                    platform: this.platform,
                    query,
                    availableMetrics,
                    metrics,
                    config,
                    property: this.property,
                });

                this.chart = new AdsChart({
                    title: this.title,
                    platform: this.platform,
                    config: this.config,
                    property: this.property,
                    properties,
                    metrics: chartMetrics,
                    allMetrics: this.metrics,
                });

                this.grid = new AdsGrid({
                    title: this.title,
                    platform: this.platform,
                    node: new MetricsFunnelNode({ property: this.property, allProperties: properties, level: 0 }),
                    metrics: this.metrics,
                    availableMetrics: this.availableMetrics,
                    config,
                    property: this.property,
                    query,
                });
            }
        };
    },
];

export type IAdViewFactory = AngularInjected<typeof AdViewFactory>;
export type IAdView = InstanceType<IAdViewFactory>;

export const AdsViewFactory = () => [
    '$q',
    'AdView',
    'MetricsHierarchy',
    'QueryMetrics',
    function AdsViewModel(
        $q: angular.IQService,
        AdView: IAdViewFactory,
        MetricsHierarchy: { fetch: () => Promise<IPropertyDefinition[]> },
        QueryMetrics: IQueryMetrics,
    ) {
        class AdsView {
            adsViews: IAdView[] | null = null;
            platforms: string[] = [];
            config: AdsConfig | null = null;
            organizationId: string | null = null;
            adsStorage: IAdsStorage = {};

            constructor(query: IQuery) {
                this.init(query);
            }

            init(query: IQuery) {
                void $q
                    .all([
                        Auth.getOrganization(),
                        fetchAdsConfig(),
                        QueryMetrics.fetch(),
                        MetricsHierarchy.fetch(),
                        AdsStorage.getStorage(),
                    ])
                    .then(([orgId, config, allMetrics, properties, adsStorage]) => {
                        if (!config) return;
                        this.organizationId = orgId;
                        this.adsStorage = adsStorage;
                        this.config = config;
                        this.platforms = Object.keys(config.platforms);
                        const kpiByColorDictionary = getKpiByColor(this.platforms, config);
                        const adsViews: IAdView[] = [];

                        this.platforms.forEach(platform => {
                            const platformConfig = config.platforms[platform];
                            if (typeof platformConfig === 'undefined') return;
                            this.adsStorage[platform] = this.adsStorage[platform] ?? { chart: [] };
                            const platformKpis = platformConfig.kpis ?? [];
                            const { availableMetrics, selectedMetrics: metrics } = processMetrics(
                                allMetrics,
                                platformKpis,
                                kpiByColorDictionary,
                            );

                            // Get Chart Metrics
                            const chartKpis =
                                this.adsStorage[platform]?.chart ?? platformConfig.chart.kpis ?? platformKpis;

                            const adView = new AdView({
                                availableMetrics,
                                config: platformConfig,
                                platform,
                                properties,
                                query,
                                metrics,
                                chartMetrics: metrics.filter(metric => chartKpis.includes(metric.field)),
                            });

                            this.adsStorage[platform] = {
                                ...(this.adsStorage[platform] ? this.adsStorage[platform] : {}),
                                ...{ chart: chartKpis },
                            };
                            adsViews.push(adView);
                        });

                        this.adsViews = adsViews;
                        void AdsStorage.saveStorage(this.adsStorage);
                    });
            }
        }

        return {
            fetchView: (query: IQuery) => new AdsView(query),
        };
    },
];

export type IAdsViewFactory = AngularInjected<typeof AdsViewFactory>;
export type IAdsView = ReturnType<IAdsViewFactory['fetchView']>;

interface AdsControllerScope extends angular.IScope {
    view: IAdsView | null;
}
export const AdsControllerFactory = () => [
    '$rootScope',
    '$scope',
    'AdsView',
    'DashboardQuery',
    function AdsController(
        $rootScope: DashboardRootScope,
        $scope: AdsControllerScope,
        AdsView: IAdsViewFactory,
        DashboardQuery: IDashboardQuery,
    ) {
        $scope.view = null;
        const updateView = () => ($scope.view = AdsView.fetchView(DashboardQuery.get()));

        $scope.$on(
            '$destroy',
            $rootScope.$on('query.refresh', () => updateView()),
        );
        updateView();
    },
];

import _ from 'lodash';
import Utils from '../../lib/utils';

export function isTotalRow<T extends { property0?: unknown }>(row: undefined | null | T, labels?: string[]) {
    row ??= {};
    let index = 0;
    labels ??= ['$total', '$loadingTotal'];
    while (`property${index}` in row) index++;
    return Array.from({ length: index }, (_, i) => i).some(i => {
        // return row?.[`property${i}`] === '$total' || row?.[`property${i}`] === '$loadingTotal';
        return labels.includes(row?.[`property${i}`]); // === '$total' || row?.[`property${i}`] === '$loadingTotal';
    });
}

export function isLoadingTotalRow<T extends { property0?: string | number | null }>(row: undefined | null | T) {
    return isTotalRow(row, ['$loadingTotal']);
}

export type IMetricsGridConfigViewsColumns = Record<string, number | undefined>;
export interface IMetricsGridConfigViews {
    columns?: IMetricsGridConfigViewsColumns;
}

export function deserializeViews(views: unknown): { columns?: Record<string, number> } {
    if (!Utils.isObject(views)) return {};
    if (!Utils.isObject(views.columns) && !Utils.isObject(views.filters)) return {};
    const columns: Record<string, number> = {};
    for (const [columnName, value] of Object.entries(views.columns ?? {})) {
        if (typeof value === 'number') columns[columnName] = value;
    }

    return {
        columns: _.isEmpty(columns) ? {} : columns,
    };
}

export const getRowsPropertyKeys = (row: ISimpleRow): string[] => {
    return Object.keys(row)
        .filter(key => /^property\d+$/.test(key))
        .sort((a, b) => parseInt(a.replace('property', ''), 10) - parseInt(b.replace('property', ''), 10));
};

export type ISimpleRow = Record<string, unknown>;
export type IComplexRow = Record<string, ISimpleRow>;
export type IMultiLevelRow = Record<string, ISimpleRow | IComplexRow>;

const createPropertyTree = (rows: ISimpleRow[]): IMultiLevelRow => {
    const tree: IMultiLevelRow = {};

    for (const row of rows) {
        let currentNode = tree;

        // Sort property keys to ensure correct hierarchy
        const propertyKeys = getRowsPropertyKeys(row);

        // Create tree levels
        for (const key of propertyKeys) {
            const value = (() => {
                const propertyKey = row[key];
                if (typeof propertyKey === 'string') return propertyKey;
                return String(propertyKey);
            })();

            if (!currentNode[value]) {
                currentNode[value] = {};
            }

            // currentNode = currentNode[value] as IMultiLevelRow;
            currentNode = currentNode[value];
        }

        // add original object at the deepest level
        Object.assign(currentNode, row);
    }

    return tree;
};

export function removeDuplicatesStartingFromPropertyN(
    rows: IMultiLevelRow[],
    startPropertyN: number,
): IMultiLevelRow[] {
    const seen = new Set<string>();
    const result: IMultiLevelRow[] = [];

    for (const row of rows) {
        const key = Object.keys(row)
            .filter(key => /^property\d+$/.test(key))
            .filter(key => parseInt(key.replace('property', ''), 10) >= startPropertyN)
            .map(key => row[key])
            .join('|');

        if (!seen.has(key)) {
            seen.add(key);
            result.push(row);
        }
    }

    return result;
}

export function mapRowsAsColumns(rows: Record<string, unknown>[], propertiesToGroupN: number): IMultiLevelRow[] {
    const propertiesTree = createPropertyTree(rows);
    const mappedRows = rows.map<IMultiLevelRow>(item => {
        const filteredItem = Object.keys(item)
            .filter(key => /^property\d+$/.test(key))
            .reduce<IMultiLevelRow>((acc: IMultiLevelRow, key) => {
                acc[key] = item[key];
                return acc;
            }, {});

        return {
            ...filteredItem,
            propertiesTree,
        };
    });
    // return mappedRows;
    return removeDuplicatesStartingFromPropertyN(mappedRows, propertiesToGroupN);
}

export function mapTotalRowsAsColumns(
    rowsRaw: Record<string, unknown>[],
    properties: number, // in this case properties in the rows - horizontal listed
    propertiesToGroupN: number, // is this case is the properties as columns - vertical listed
): IMultiLevelRow[] {
    const propertiesAsColumnsList = Array.from({ length: propertiesToGroupN }, (_, i) => `property${i}`);
    const propertiesAsRowsList = Array.from({ length: properties }, (_, i) => `property${i + propertiesToGroupN}`);

    const rows = rowsRaw.filter(row => {
        const hasTotalInColumns = propertiesAsColumnsList.some(key => row[key] === '$total');
        if (hasTotalInColumns) return false;

        const hasEveryPropertyAsTotal = propertiesAsRowsList.every(key => row[key] === '$total');
        return hasEveryPropertyAsTotal;
    });

    const propertiesTree = createPropertyTree(rows);
    const mappedRows = rows.map<IMultiLevelRow>(item => {
        const filteredItem = Object.keys(item)
            .filter(key => /^property\d+$/.test(key))
            .reduce<IMultiLevelRow>((acc: IMultiLevelRow, key) => {
                acc[key] = item[key];
                return acc;
            }, {});

        return {
            ...filteredItem,
            propertiesTree,
        };
    });

    return removeDuplicatesStartingFromPropertyN(mappedRows, propertiesToGroupN);
}
